import { useContextData } from "../../context/DataContext";
import GenderSelection from "./GenderSelect";
import AgeProfileSelection from "./AgeProfileSelect";
import EmotionSelection from "../audio/EmotionSelect";
import { useTranslation } from "react-i18next";
import AccentFormFieldContainer from "./AccentFormFieldContainer";
import LanguageSelection from "../general/LanguageSelect";
import { useEffect } from "react";

/**
 * Renders a form component for selecting accent details.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components to render within the form.
 * @param {string} props.type - The type of accent.
 * @param {boolean} [props.isForAnotherUser=false] - Indicates if the form is for another user.
 * @returns {ReactNode} The rendered form component.
 */
const AccentForm = ({children, type, isForAnotherUser = false}) => {
    const {t} = useTranslation(["Common", "HomePage"]);
    const {setFormState} = useContextData()
    const title = isForAnotherUser ? t("FamilyFriends") : t(type.charAt(0).toUpperCase() + type.slice(1))

    useEffect(() => {
        setFormState(prev => ({...prev, emotion: "none"}))
    }, [])

    return (
        <div>
            <h2 className="text-xl font-semibold">
                {t("HomePage:AccentForm", {type: title})}
            </h2>

            {children}

            <AccentFormFieldContainer>
                <GenderSelection/>
            </AccentFormFieldContainer>

            <AccentFormFieldContainer>
                <AgeProfileSelection/>
            </AccentFormFieldContainer>
        </div>
    )
}

export default AccentForm;