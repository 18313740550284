/**
 * Renders a back button component.
 * @returns {JSX.Element} The back button component.
 */
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const BackButton = () => {
    const navigation = useNavigate();

    return (
        <FontAwesomeIcon cursor="pointer" icon="arrow-left" size="2x" onClick={() => navigation(-1)}/>
    )
}

export default BackButton;