import { useEffect, useState } from "react"
import { TextField, Text } from "@aws-amplify/ui-react";

/**
 * LimitedTextField component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.limit - The character limit for the text field.
 * @param {function} props.onChange - The callback function to handle text field changes.
 * @param {boolean} [props.required=false] - Indicates if the text field is required.
 * @param {boolean} [props.hasError=false] - Indicates if the text field has an error.
 * @param {function} [props.validator=false] - The validation function to validate the text field value.
 * @param {string} [props.value] - The initial value of the text field.
 * @returns {JSX.Element} The LimitedTextField component.
 */
const LimitedTextField = ({limit, onChange, required = false, hasError = false, validator = false, ...props}) => {
    const [text, setText] = useState("");
    const [isValid, setIsValid] = useState(true)

    const onChangeHandler = (e) => {
        onChange(e);
        setText(e.target.value);
        
        if (validator && !validator(e.target.value)) {
            setIsValid(false)
        }
        else {
            setIsValid(true)
        }
    }

    useEffect(() => {
        if (typeof props.value === "string") {
            setText(props.value);
        }
    }, [props.value])

    return (
        <TextField
            required={required}
            hasError={!isValid || hasError}
            onChange={onChangeHandler}
            innerEndComponent={
                <Text
                    className="charCountText"
                    style={text.length > limit ? {opacity: "100%"} : null} 
                    color={text.length > limit ? "red" : null} 
                >
                    {`${text.length} / ${limit}`}
                </Text>
            }
            {...props}
        />
    )
}

export default LimitedTextField;