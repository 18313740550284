import AWS from "aws-sdk";
import { Heading, ScrollView, Flex, SelectField, Button } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { useContextData } from "../../context/DataContext";
import { getRecordingProgression, listTranscriptsByEmotion } from "../../graphql/customQueries";
import { API, graphqlOperation } from "aws-amplify";
import { CircularProgress, MobileStepper, Skeleton, capitalize, Button as MUIButton, Typography } from "@mui/material";
import { decodeUriString } from "../../helpers/text";
import { useNavigate } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { useTranslation } from "react-i18next";
import TranscriptSentence from "./TranscriptSentence";

const mockTranscript = `
Sentence 1: The sunny weather lasting all day.
Sentence 2: With the temperature soaring high.
Sentence 3: Humidity is too high.
Sentence 4: I don't know how long I can last.
Sentence 5: Maybe today, the heat will take me down.
`;

const speakerEmotionEmojis = {
  none: "😐",
  happy: "😁",
  sad: "😢",
  fear: "😨",
  surprise: "😮",
  disgust: "🤢",
  angry: "😡"
}

/**
 * Represents a transcript component that displays a transcript with speaker bubbles.
 * 
 * @component
 * @param {String} classes - class names
 * @param {Object} data - The data object containing information about the transcript.
 * @param {Boolean} isRecording - User is recording
 * @param {Function} onFinish - transcript progress complete signal
 * @param {Function} onSentenceChange - The start times and end times for the recorded sentences.
 * @param {Boolean} playback - used for playback to iterate through sentences based on time progression of the transcript
 * @param {Function} setIsPlaybackEnabled - used for playback to iterate through sentences based on time progression of the transcript
 * @param {Function} onTranscriptFilenameChange - The callback function to handle changes in the transcript filename.
 * @param {Function} onTitleChange - The callback function to handle changes in the transcript title.
 * @returns {JSX.Element} The JSX element representing the Transcript component.
 */
const Transcript = (
  { classes = "",
    data, 
    isRecording = false, 
    onFinish = () => {}, 
    onSentenceChange = (time, stepProgress) => {}, 
    playback = false,
    setIsPlaybackEnabled = () => {}, 
    onTranscriptFilenameChange = (name) => {}, 
    onTitleChange = (title) => {}
  }
) => {
    const navigation = useNavigate()
    const {t} = useTranslation(["Common"])
    const s3 = new AWS.S3();
    const {selectedSiteLanguage, selectedAgeProfile} = useContextData()
    const [title, setTitle] = useState("");
    const [transcript, setTranscript] = useState(null);
    const [transcriptsInfoByEmotion, setTranscriptsInfoByEmotion] = useState([]);
    const [transcriptBubbles, setTranscriptBubbles] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [transcriptSentences, setTranscriptSentences] = useState([])
    const [sentencesEstTimeToRead, setSentencesEstTimeToRead] = useState([])
    const [transcriptProgress, setTranscriptProgress] = useState([])
    const [lastCompleteReadingProgress, setLastCompleteReadingProgress] = useState([])
    const [sentenceStartTime, setSentenceStartTime] = useState(null);

    const getTranscript = (params, maxDepth, fetchDepth = 0) => {
      s3.getObject(params, (err, res) => {
        if (err) {
          fetchDepth += 1
          if (fetchDepth === maxDepth) {
            alert("Error: Could not find any transcript suitable for your accent profile")
            navigation(-1)
            return
          }

          let currentKey = params.Key
          params.Key = `transcripts/${data.emotion}${selectedSiteLanguage !== "en" && fetchDepth == 1 ? `_${selectedSiteLanguage}` : ""}.txt` // fallback

          if (params.Key !== currentKey) {
            getTranscript(params, maxDepth, fetchDepth)
          }
          else {
            console.error(err)
          }
          
          return;
        }

        console.log('transcript fetched successfully');
        onTranscriptFilenameChange(params.Key)
        setTranscript(res.Body.toString());
      })
      // setTranscript(mockTranscript)
    }

    const fetchTranscriptHandler = () => {
      const params = {
        Bucket: 'accenttranscripts',
        Key: `transcripts/${data.emotion}_${data.age}${selectedSiteLanguage === "en" ? "" : `_${selectedSiteLanguage}`}.txt`
        // Key: `transcripts/${data.emotion}_${decodeUriString(title)}.txt`,
      }

      const maxFailures = params.Key.match(/\/([^\/]+)\./)[1].split("_").length
      getTranscript(params, maxFailures)
    }

    const generateTranscriptSentences = (transcript) => {
      // Split the text into lines first
      const lines = transcript.split('\n').filter(line => line.trim() !== '');

      // Extract the actual sentences by removing the "Sentence n:" part
      const sentencesArray = lines.map(line => {
          const colonIndex = line.indexOf(':');
          if (colonIndex !== -1) {
              return line.slice(colonIndex + 1).trim(); // Extract and trim the sentence after the colon
          }
          return line.trim(); // In case there's no colon, just trim the line
      });


      // Return the array with trimmed sentences
      setTranscriptSentences(sentencesArray.map(sentence => sentence.trim()));
    }

    const handleNext = () => {
      let newProgress = transcriptProgress;
      if (!playback) {
        // record time
        const sentenceEndTime = new Date()
        newProgress.push(sentenceEndTime - sentenceStartTime)
        setTranscriptProgress([...newProgress])
      }

      if (activeStep < transcriptSentences.length - 1) {
        setActiveStep((prev) => prev + 1)
      }
      else {
        if (playback) {
          setActiveStep(0)
          setIsPlaybackEnabled(false)
        }
        else {
          onFinish()
          setLastCompleteReadingProgress(newProgress)  
        }
      }

      if (!playback)
        setSentenceStartTime(new Date())
    }

    useEffect(() => {
      if (data) {
        // API.graphql(graphqlOperation(listTranscriptsByEmotion, {emotion: data.emotion})).then((res) => {
        //   const transcripts = res.data.listAccentTranscripts.items;
        //   setTitle(transcripts[0].title);
        //   setTranscriptsInfoByEmotion(res.data.listAccentTranscripts.items);
        // }).catch((err) => {
        //   console.error(err)
        // })
        fetchTranscriptHandler()
      }
    }, [data.emotion])

    useEffect(() => {
      // fetchTranscriptHandler();
      onTitleChange(title);
    }, [title])

    useEffect(() => {
      setSentencesEstTimeToRead([])

      // for transcript recording review
      API.graphql(graphqlOperation(getRecordingProgression, {AccentId: data.PrimaryKey})).then((res) => {
        const keys = Object.keys(res.data);
        const progression = res.data[keys[0]]?.recordingProgression
        setLastCompleteReadingProgress(progression)
      })
      .catch((err) => {
        console.error(err)
      })

      // if (transcript !== mockTranscript) {
      //   generateTranscriptBubbles(transcript);
      // }


      if (transcript !== null) {
        generateTranscriptSentences(transcript)
      }
    }, [transcript])

    useEffect(() => {
      if (isRecording) {
        setTimeout(() => {
          setTranscriptProgress([])
        }, 1000);
        // for the initial sentence only
        setSentenceStartTime(new Date())
      }
      else {
        setActiveStep(0)
      }
    }, [isRecording])

    useEffect(() => {
      if (transcriptProgress.length > 0)
        onSentenceChange(transcriptProgress, (activeStep / transcriptSentences.length) * 100)
    }, [transcriptProgress])

    return (
        transcriptSentences.length !== 0 ?
        <Flex className={"transcript-container " + classes} direction={"column"} justifyContent={"space-between"} alignItems={"center"}>
          <SwipeableViews
            style={{maxWidth: "90vw", marginTop: "auto"}}
            slideStyle={{display: "flex", justifyContent: "center", alignItems: "center"}}
            index={activeStep}
            disabled={true}
          >
            {transcriptSentences.map((sentence, i) => (
              <Flex key={i} textAlign={"center"} justifyContent={"center"} padding={5}>
                <TranscriptSentence 
                  text={sentence}
                  onAnalysis={(data) => setSentencesEstTimeToRead(prev => [...prev, (data.seconds) * 1000])}
                  isPlaying={(playback || isRecording) && i === activeStep}
                  onFinishPlaying={() => handleNext()}
                />
              </Flex>
            ))}
          </SwipeableViews>
          <MobileStepper
            style={{width: "100%", marginTop: "auto", justifyContent: "center"}}
            position="static"
            variant="text"
            steps={transcriptSentences.length}
            activeStep={activeStep}
          />
        </Flex>
        : <CircularProgress/>
    )
}

export default Transcript;