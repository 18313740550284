import React from 'react';
import { useContextData } from '../../context/DataContext';
import { useTranslation } from 'react-i18next';
import LimitedTextField from '../general/LimitedTextField';
import { Label, Flex, Text } from '@aws-amplify/ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { keywordCharLimit, maxTextBoxes } from '../../constants/accentFormDataConstants';

/**
 * Renders a component that displays dynamic text boxes for entering keywords.
 * @returns {JSX.Element} The rendered component.
 */
function DynamicTextBoxes() {
  const {t} = useTranslation(["Common"])
  const { textBoxes, handleTextBoxChange, handleAddTextBox, handleDeleteTextBox } = useContextData();

  return (
    <Flex direction={"column"}>
      <Label htmlFor='keywords'>{t("Keywords")}</Label>
      <Label className='amplify-field__description' htmlFor='keywords-description'>
        {t("KeywordsDescription")}
        <p>{t("KeywordsRestriction")}</p>
      </Label>
      <Flex direction={"column"}>
        <Text color="gray">{textBoxes.length} / {maxTextBoxes}</Text>
        {textBoxes.map((text, index) => {
          return (
            <Flex key={index} alignItems={"center"}>
              <LimitedTextField
                style={{marginBottom: 5}}
                type="text"
                value={text}
                placeholder={t("KeywordsExamples")}
                limit={keywordCharLimit}
                onChange={(e) => handleTextBoxChange(index, e.target.value)}
              />

              {index === textBoxes.length - 1 ? (
                <FontAwesomeIcon 
                  color={text.length === 0 ? 'gray' : null}
                  cursor={"pointer"}
                  size='2x'
                  icon='square-plus'
                  onClick={() => {handleAddTextBox(keywordCharLimit)}}
                />
              ) : 
                <FontAwesomeIcon
                  color={"red"}
                  cursor={"pointer"}
                  size="xl"
                  icon="trash-can"
                  onClick={() => {
                    handleDeleteTextBox(index)
                  }}
                />
              }
            </Flex>
          )
        })}
      </Flex>
    </Flex>
  );
}

export default DynamicTextBoxes;
