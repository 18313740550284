import AWS from "aws-sdk";
import "../styles/transcriptGenerationPage.scss";
import { Button, TextField, TextareaAutosize } from "@mui/material";
import EmotionSelection from "../components/audio/EmotionSelect";
import AgeProfileSelection from "../components/forms/AgeProfileSelect";
import { useContextData } from "../context/DataContext";
import { useState } from "react";
import withAppNavbar from "../components/general/withAppNavbar";

const s3 = new AWS.S3();
const bucket = "accenttranscripts";
const transcriptsPrefix = "transcripts/"; 

/**
 * Represents the Admin Transcript Generation Page component.
 * This component allows the admin to generate and upload transcripts.
 * @returns {JSX.Element} The rendered Admin Transcript Generation Page component.
 */
const AdminTranscriptGenerationPage = () => {
    const {selectedEmotion, selectedAgeProfile, selectedSiteLanguage} = useContextData();
    const [transcript, setTranscript] = useState(""); 

    // for transcript template, see /Docs/Admin.md
    const onSubmitHandler = async () => {
        if (transcript.trim() === "") {
            alert("Transcript must not be empty!");
            return;
        }
        
        const fileName = `${selectedEmotion}${selectedAgeProfile === "" ? "" : `_${selectedAgeProfile}`}${selectedSiteLanguage === "en" ? "" : `_${selectedSiteLanguage}`}`;
        const params = {
            Bucket: bucket,
            Key: `${transcriptsPrefix}${fileName}.txt`, // You can modify this as you wish
            Body: transcript,
        };
    
        console.log("uploading transcript!")
        try {
            const result = await s3.upload(params).promise();
            console.log(result)
            return result;
        }
        catch (err) {
            console.error("Error uploading transcript to S3:", err);
        }
    }

    return (
        <div className="transcriptGenerationContainer">
            <EmotionSelection excluding={[]} fieldProps={{descriptiveText: null}}/>
            <AgeProfileSelection />
            <TextareaAutosize placeholder="Enter Transcript" onChange={(e) => {setTranscript(e.currentTarget.value)}}/>
            <Button onClick={onSubmitHandler}>Submit</Button>
        </div>
    )
}

export default withAppNavbar(AdminTranscriptGenerationPage);