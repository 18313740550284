import AWS from "aws-sdk";
import { API, graphqlOperation } from "aws-amplify";
import { createFantasyAccent, deleteFantasyAccent } from "../graphql/mutations";
import { listFantasyAccents } from "../graphql/customQueries";
import { delAccentRecording } from "./RecordingService";

/**
 * Posts a fantasy accent to the database.
 * @param {Object} data - The data of the fantasy accent to be posted.
 * @param {string} accentId - The ID of the accent.
 * @returns {Promise<void>} - A promise that resolves when the accent is successfully posted.
 */
async function postFantasyAccent(data, accentId) {
    try {
        await API.graphql(graphqlOperation(createFantasyAccent, { input: data }));
        console.log("successfully uploaded to DB");
    } catch (err) {
        console.error(err);
        await delAccentRecording(accentId);
    }
}

/**
 * Retrieves fantasy accents from the database.
 * @param {Object} options - Additional options for retrieving the accents.
 * @returns {Promise<Object>} - A promise that resolves with the retrieved fantasy accents.
 */
async function getFantasyAccents({...options} = {}) {
    try {
        const data = await API.graphql(graphqlOperation(listFantasyAccents, {...options}));
        return data;
    } catch (err) {
        console.error(err);
    }
}

/**
 * Retrieves fantasy accents from the database based on status and filter.
 * @param {string|null} status - The status of the accents to retrieve. If null, retrieves all accents.
 * @param {Object} filter - Additional filter options for retrieving the accents.
 * @param {Object} options - Additional options for retrieving the accents.
 * @returns {Promise<Object>} - A promise that resolves with the retrieved fantasy accents.
 */
async function getFantasyAccentsByStatus(status = null, filter = {}, ...options) {
    if (status === null) {
        return await getFantasyAccents({...options, filter});
    }

    return await getFantasyAccents({
        ...options,
        filter: { status: { eq: status }, ...filter},
    });
}

/**
 * Deletes a fantasy accent from the database.
 * @param {string} key - The primary key of the accent to be deleted.
 * @returns {Promise<boolean>} - A promise that resolves with a boolean indicating whether the deletion was successful.
 */
async function delFantasyAccent(key) {
    try {
        await API.graphql(graphqlOperation(deleteFantasyAccent, {input: { PrimaryKey: key }}));
        console.log("fantasy accent record successfully deleted from DB");
        return true;
    }
    catch (err) {
        console.error(err);
        return false;
    }
}

export {
    postFantasyAccent,
    getFantasyAccents,
    getFantasyAccentsByStatus,
    delFantasyAccent
}