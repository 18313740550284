import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react"
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useContextData } from "../context/DataContext";
import withAppNavbar from "../components/general/withAppNavbar";

/**
 * Renders the authentication page.
 *
 * @returns {JSX.Element} The authentication page component.
 */
const AuthPage = () => {
    const navigation = useNavigate();
    const location = useLocation();
    const { route, toSignIn, toSignUp, toResetPassword } = useAuthenticator((context) => [context.route]);
    const [to, setTo] = useState(location.state?.to);
    let from = location.state?.from?.pathname || '/';
    const customSignUpFormFields = location.state?.referralCode ? {
        signUp: {
            "custom:ReferralCodeUsed": {
                label: "Referral Code",
                placeholder: "N/A",
                isRequired: false,
                isReadOnly: true,
                defaultValue: location.state?.referralCode ?? "" // this will be the value sent up
            }
        }
    } : {};

    useEffect(() => {
        if (route === 'authenticated') {
            navigation(from, { replace: true });
        }

        switch (to) {
            case 'signIn':
                toSignIn();
                break;
            case 'signUp':
                toSignUp();
                break;
            case 'resetPassword':
                toResetPassword();
                break;
            default:
                break;
        }

        setTo(null);
    }, [route, navigation, from]);

    useEffect(() => {
        if (location.state?.referralCode) {
            // hide referral code from UI
            let inputElement = document.querySelector('input[name="custom:ReferralCodeUsed"]');
            if (inputElement) {
                let field = inputElement.closest('div').parentElement.parentElement;
                field.style.display = "none";
            }
        }
    }, [customSignUpFormFields])

    return (
        <div style={styles.container}>
            <Authenticator 
                formFields={customSignUpFormFields}
            />
        </div>
    );
}

const styles = {
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
    }
}

export default withAppNavbar(AuthPage);