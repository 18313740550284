import { useEffect, useState } from "react";
import ReactPlayer from "react-player";

const TutorialPlayer = ({url = "", playing = false, muted = false}) => {
    const defaultSizeFactor = 3;
    const [sizeFactor, setSizeFactor] = useState(defaultSizeFactor);
    const onResize = () => {
        let newSize = defaultSizeFactor
        if (window.innerWidth < 1000) {
            newSize = defaultSizeFactor + 1
        }
        if (window.innerWidth < 700) {
            newSize = defaultSizeFactor + 2
        }
        if (window.innerWidth < 500) {
            newSize = defaultSizeFactor + 3
        }

        setSizeFactor(newSize)
    }

    useEffect(() => {
        window.addEventListener("resize", onResize)

        return () => {
            window.removeEventListener("resize", onResize)
        }
    }, [])

    return (
        <ReactPlayer
            className="tutorial-video"
            style={{maxWidth: 640, maxHeight: 360}}
            width={`${sizeFactor * 16}vw`}
            height={`${sizeFactor * 9}vw`}
            url={url}
            playing={playing}
            muted={muted}
            controls
        />
    )
}

export default TutorialPlayer;