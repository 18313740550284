import { SelectField } from "@aws-amplify/ui-react";
import { capitalize } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/**
 * UserRecordingsFilterSelect component represents a filter select field for user recordings.
 *
 * @component
 * @param {string} category - The category of the filter.
 * @param {Array<string>} categoryValues - The values for the filter category.
 * @param {function} onChange - The callback function to be called when the filter value changes.
 * @returns {JSX.Element} - The rendered UserRecordingsFilterSelect component.
 */
const UserRecordingsFilterSelect = ({category, categoryValues, onChange = (e) => {}}) => {
    const {t} = useTranslation(["AccentCategories", "Common"])
    const [selected, setSelected] = useState(null)

    const onSelectHandler = (e) => {
        const value = e.target.value !== "" ? e.target.value : null;
        setSelected(value)
        onChange(value)
    }

    useEffect(() => {
        setSelected(null)
    }, [categoryValues])

    return (
        <SelectField value={selected ?? ""} className="filter-select-field" onChange={onSelectHandler} label={t(capitalize(category))} marginRight={10}>
            <option value="">- {t("Common:All")} -</option>
            {[...categoryValues].map((value) => (
                <option value={value}>{capitalize(value)}</option>
            ))}
        </SelectField>
    )
}

export default UserRecordingsFilterSelect;