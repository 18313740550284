import { Dialog as MUIDialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, CircularProgress  } from "@mui/material";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';

/**
 * Dialog component for displaying a dialog box with customizable title, content, and buttons.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} [props.open=false] - Whether the dialog is open or not.
 * @param {function} onClose - The function to be called when the dialog is closed.
 * @param {string} title - The title of the dialog.
 * @param {function} [yesFn=null] - The function to be called when the "Yes" button is clicked.
 * @param {string} [yesBtnText=null] - The text to be displayed on the "Yes" button.
 * @param {function} [noFn=null] - The function to be called when the "No" button is clicked.
 * @param {string} [noBtnText=null] - The text to be displayed on the "No" button.
 * @param {string} [text=null] - The text content of the dialog.
 * @param {ReactNode} [content=<></>] - The additional content of the dialog.
 * @returns {JSX.Element} The rendered Dialog component.
 */
const Dialog = ({open = null, onClose, title, yesFn = null, yesBtnText = null, noFn = null, noBtnText = null, text = null, content = <></>, ...props}) => {
    const {t} = useTranslation(["Common"])
    const uuid = uuidv4();
    const [isOpen, setIsOpen] = useState(open || false);
    const [noClicked, setNoClicked] = useState(false);
    const [yesClicked, setYesClicked] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    
    const handleClose = () => {
        onClose(false);
    }

    useEffect(() => {
        setIsOpen(open);
    }, [open])

    useEffect(() => {
        setButtonClicked(noClicked || yesClicked)
    }, [noClicked, yesClicked])

    return (
        <MUIDialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby={`alert-dialog-title-${uuid}`}
            aria-describedby={`alert-dialog-description-${uuid}`}
            {...props}
        >
            <DialogTitle id={`alert-dialog-title-${uuid}`}>
                {title}
            </DialogTitle>
            <DialogContent>
                {text &&
                    <DialogContentText id={`alert-dialog-description-${uuid}`}>
                        {text}
                    </DialogContentText>
                }
                {content}
            </DialogContent>
            <DialogActions>
                {noFn &&
                    <Button
                        disabled={buttonClicked}
                        variant="outlined"
                        onClick={() => {
                            setNoClicked(true)
                            setTimeout(async () => {
                                await noFn();
                                handleClose();
                                setButtonClicked(false)
                                setNoClicked(false)
                            }, 500)
                        }} 
                    >
                        {noClicked ?
                            <CircularProgress size="80%"/>
                            :
                            noBtnText ?? t("No")
                        }
                    </Button>
                }
                {yesFn &&
                    <Button
                        disabled={buttonClicked}
                        variant="outlined"
                        onClick={() => {
                            setYesClicked(true)
                            setTimeout(async () => {
                                await yesFn();
                                handleClose();
                                setButtonClicked(false)
                                setYesClicked(false)
                            }, 500)
                        }} 
                        autoFocus
                    >
                        {yesClicked
                            ? 
                            <CircularProgress size="80%"/>
                            :
                            noFn
                                ? 
                                yesBtnText ?? t("Yes") 
                                : 
                                yesBtnText ?? t("Ok")
                        }
                    </Button>
                }
            </DialogActions>
        </MUIDialog>        
    )
}

export default Dialog;