import {  styled, Chip, Paper, capitalize } from "@mui/material";
import { useContextData } from "../../context/DataContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import KeywordsChips from "../audio/KeywordsChips";
import { useTranslation } from "react-i18next";

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

/**
 * Renders the description of an accent.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.type - The type of accent.
 * @param {Object} props.data - The accent data.
 * @returns {JSX.Element} The rendered component.
 */
const AccentDescription = ({type, data}) => {
    const {t} = useTranslation(["Common", "AccentAttributes"])
    const chips = data.keywords.map((keyword, index) => ({key: index, label: capitalize(keyword)}));
    const emotionDescription = `${data.emotion !== "none" ? `${t(`AccentAttributes:Common.Emotion.${capitalize(data.emotion)}`)},` : ""}`
    const genderDescription = `${data.gender !== "other" ? `${t(`AccentAttributes:Common.Gender.${capitalize(data.gender)}`)},` : ""}`
    
    function generateAccentDescription() {
        let accentDescription;
        if (type === "regional") {
            accentDescription = `
                ${t(`Common:YouAreAThing`, {thing: emotionDescription})}
                ${genderDescription}
                ${data?.age ? t(`AccentAttributes:Common.AgeProfile.${capitalize(data.age)}`) : ""} ${t("Common:FromThing", {thing:
                    `
                    ${data.area !== "" ? `${capitalize(data.area)},` : ""} 
                    ${data.state !== "" ? capitalize(data.state) + "," : ""} 
                    ${capitalize(data?.country ?? "")}
                    `     
                })} 
            `
        }
        else {
            accentDescription = `
                ${t(`Common:YouAreAThing`, {thing: emotionDescription})}
                ${genderDescription}
                ${t(`AccentAttributes:Common.AgeProfile.${capitalize(data.age)}`)},
                ${t(`AccentAttributes:Fantasy.Race.${capitalize(data.race)}`)}
            `
        }

        return accentDescription;
    }


    return (
        <>
            <span style={{textAlign: "center"}}>{generateAccentDescription()}</span>
            <KeywordsChips keywords={data.keywords}/>
        </>
    )
}

export default AccentDescription;