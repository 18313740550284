import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppBar, Dialog, IconButton, Toolbar, Typography } from "@mui/material";

/**
 * A fullscreen dialog component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The content of the dialog.
 * @param {string} [props.title=""] - The title of the dialog.
 * @param {boolean} [props.isOpen=false] - Whether the dialog is open or not.
 * @param {function} props.onClose - The function to be called when the dialog is closed.
 * @returns {JSX.Element} The rendered fullscreen dialog component.
 */
const FullscreenDialog = ({children, title = "", isOpen = false, onClose}) => {
    return (
        <Dialog
            fullScreen
            open={isOpen}
            onClose={onClose}
        >
            <AppBar style={{marginBottom: 25}} position="relative">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <FontAwesomeIcon icon={"arrow-left"}/>
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {title}
                    </Typography>
                </Toolbar>
            </AppBar>
            {children}
        </Dialog>
    )
}

export default FullscreenDialog;