import { Button, Flex, useAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "@aws-amplify/auth";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "./Dialog";
import { emailCharLimit } from "../../constants/accentFormDataConstants";
import { isValidEmail } from "../../helpers/text";
import LimitedTextField from "./LimitedTextField";
import AccentFormFieldContainer from "../forms/AccentFormFieldContainer";
import { API, graphqlOperation } from "aws-amplify";
import { sendReferralEmail } from "../../graphql/mutations";

/**
 * Share Component
 * 
 * This component allows users to share a referral link through various methods such as copying the link, sending an email, or sharing on mobile devices.
 * 
 * @returns {JSX.Element} The rendered Share component.
 */
const Share = () => {
    const {t} = useTranslation(["HomePage", "CommonCommon:"])
    const {user} = useAuthenticator((context) => [context.user])
    const isMobileShareSupported = isMobile && navigator.canShare && typeof navigator.canShare === "function";
    const shareLink = `${window.location.href}referral/${btoa(user.attributes.sub)}`;
    const [email, setEmail] = useState("")
    const [emailDialogOpen, setEmailDialogOpen] = useState(false);

    const handleShare = (method = "copy") => {
        // user has activated referrals, upload to user metadata for referral verification when used at account creation
        Auth.updateUserAttributes(user, {"custom:hasActivatedReferral": "true"})
        .then(() => {
            switch (method) {
                case "copy":
                    navigator.clipboard.writeText(shareLink);
                    break;
                case "email":
                    setEmailDialogOpen(true)
                    break;
                case "mobile":
                    navigator.share({
                        title: "Project Accents",
                        text: "Invitation text message",
                        url: shareLink
                    }).catch(ignored => {})
                    break;
                default:
                    break;
            }
        })
        .catch(err => {
            console.log(err)
        });
    }

    const sendEmail = async () => {
        if (!isValidEmail(email)) return;
        console.log(email)
        const res = await API.graphql(graphqlOperation(sendReferralEmail, {recipientEmail: email, referralLink: shareLink}))
    }

    return (
        <>
            <p>{t("ShareWithFriends")}</p>
            {isMobileShareSupported
                ?
                <Button 
                    onClick={() => {handleShare("mobile")}}
                >
                    {t("Share")}
                </Button>
                :
                <Flex wrap={"wrap"} justifyContent={"center"}>
                    {/* <Button onClick={() => handleShare("email")}>{t("EmailAFriend")}</Button> */}
                    <Button onClick={() => {handleShare()}}>
                        {t("CopyLink")}
                    </Button>
                </Flex>
            }

            <Dialog
                open={emailDialogOpen}
                title={"Share via Email"}
                yesFn={sendEmail}
                yesBtnText={t("Common:SendEmail")}
                content={
                    <AccentFormFieldContainer>
                        <Flex direction="column" gap="small">
                            <label htmlFor="email" className="block text-gray-700">{t("Common:TheirEmail")}*</label>
                            <LimitedTextField
                                id="their-email"
                                required
                                limit={emailCharLimit}
                                placeholder={t("Common:EnterThing", {thing: t("Common:TheirEmail").toLowerCase()})} 
                                onChange={(e) => setEmail(e.target.value)}
                                validator={isValidEmail}
                            />
                        </Flex>
                    </AccentFormFieldContainer>
                }
                onClose={() => setEmailDialogOpen(false)}
            />
        </>
    )
}

export default Share;