import "../../styles/accentForm.scss"

/**
 * Container component for accent form fields.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components to render within the container.
 * @returns {ReactNode} The rendered component.
 */
const AccentFormFieldContainer = ({children}) => {
    return (
        <div className="fieldContainer">
            {children}
        </div>
    )
}

export default AccentFormFieldContainer