export const maxLocalRecordings = 4;

// status
export const UnderReview = 'UnderReview';
export const RaceUnderReview = 'RaceUnderReview';
export const AcceptedReview = 'AcceptedReview';
export const RejectedReview = 'RejectedReview';
export const RaceRejected = 'RaceRejected';

// status labels
export const statusLabels = {
    [UnderReview]: 'Review',
    [RaceUnderReview]: 'Race Review',
    [AcceptedReview]: 'Accepted',
    [RejectedReview]: 'Rejected',
    [RaceRejected]: 'Race Rejected',
}

// categories
export const generalCategories = ["age", "emotion", "gender"];
export const regionalCategories = ["country", "state", "area"];
export const fantasyCategories = ["race"];