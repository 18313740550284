import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/**
 * Component intended for when a user uses a referral link.
 *
 * @component
 * @returns {JSX.Element} The ReferralPage component
 */
const ReferralPage = () => {
    const navigation = useNavigate();
    const {referral} = useParams();

    useEffect(() => {
        if (!referral) {
            navigation("/");
            return;
        }

        navigation("/auth", {state: {to: "signUp", referralCode: referral}})
    }, [referral])

    return <></>;
}

export default ReferralPage;