import { Button } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import withAppNavbar from "../components/general/withAppNavbar";

/**
 * Renders the Admin Portal page.
 *
 * @returns {JSX.Element} The rendered Admin Portal page.
 */
const AdminPortalPage = () => {
    const navigation = useNavigate();

    return (
        <div>
            <h1>Admin Portal</h1>
            <Button onClick={() => {navigation("approvals")}}>Approve recordings</Button>
            <Button onClick={() => {navigation("create_transcript")}}>Create transcript</Button>
        </div>
    );
}

export default withAppNavbar(AdminPortalPage);