import { AccountSettings, Alert, Flex, Heading, useAuthenticator } from "@aws-amplify/ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import withAppNavbar from "../components/general/withAppNavbar";
import { useTranslation } from "react-i18next";

/**
 * Renders the AccountPage component.
 * 
 * @returns {JSX.Element} The rendered AccountPage component.
 */
const AccountPage = () => {
    const {t} = useTranslation(["Common", "AccountPage"])
    const navigation = useNavigate();
    const {authStatus, user} = useAuthenticator((context) => [context.authStatus, context.user]);
    const [currentEmail, setCurrentEmail] = useState(user?.attributes?.email);
    const [isEditing, setIsEditing] = useState(false);
    const [newEmail, setNewEmail] = useState(currentEmail);
    const [hasPasswordChangedSuccessfully, setHasPasswordChangedSuccessfully] = useState(undefined);
    const resetPasswordComponents = {
        ErrorMessage: () => <Alert variation="error">{`${t("AccountPage:IncorrectPassword")}.`}</Alert>,
        CurrentPasswordField: (props) => (
            <AccountSettings.ChangePassword.CurrentPasswordField
                {...props}
                label={t("AccountPage:CurrentPassword")}
            />
        ),
        SubmitButton: (props) => (
            <AccountSettings.ChangePassword.SubmitButton
                {...props}    
            >
                {t("AccountPage:UpdatePassword")}
            </AccountSettings.ChangePassword.SubmitButton>
        )
    }

    const handleEmailUpdateEvent = () => {
        updateEmailHandler(newEmail);
        setIsEditing(false);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleEmailUpdateEvent();
        }
    }

    const updateEmailHandler = async (email) => {
        if (email === currentEmail) {
            return;
        }

        try {
            const attributes = await Auth.updateUserAttributes(user, {email: email})
            console.log(attributes);

            setCurrentEmail(email);
        }
        catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (user?.attributes?.email !== currentEmail) {
            setCurrentEmail(user?.attributes?.email);
        }
    }, [user])

    useEffect(() => {
        if (authStatus !== "authenticated") { 
            navigation('/auth');
        }
    }, [authStatus])

    return (
        <Flex direction={"column"} justifyContent={"center"} alignItems={"center"} padding={25}>
            <Heading level={1}>{t("Account")}</Heading>
            <Flex justifyContent={"center"} alignItems={"center"}>
                <Heading level={5}>{t("Email")}</Heading>
                <FontAwesomeIcon cursor={"pointer"} icon={"pen-to-square"} onClick={() => setIsEditing(true)}/>
            </Flex>
            
            <Flex justifyContent={"center"} alignItems={"center"}>
                <input style={{textOverflow: "ellipsis"}} id="email" type="text" value={newEmail} disabled={true} onChange={(e) => setNewEmail(e.currentTarget.value)} onKeyDown={handleKeyDown}/>
                {/* {isEditing && <FontAwesomeIcon cursor={"pointer"} icon={"check"} onClick={handleEmailUpdateEvent}/>} */}
            </Flex>
            <Heading level={5}>{t("AccountPage:ResetPassword")}</Heading>
            
            <AccountSettings.ChangePassword
                onSuccess={() => {
                    setHasPasswordChangedSuccessfully(true);
                }}
                onError={() => {setHasPasswordChangedSuccessfully(false)}}
                components={resetPasswordComponents}
            />

            {hasPasswordChangedSuccessfully === true &&
                <Alert variation="success">
                    {`${t("AccountPage:PasswordChanged")}.`}
                </Alert>
            }
        </Flex>
    )
}

export default withAppNavbar(AccountPage);