import { useEffect, useState } from "react";
import Recorder from "../components/audio/Recorder";
import { useContextData } from "../context/DataContext";
import { useLocation, useNavigate } from "react-router-dom";
import WorkflowPageControls from "../components/general/WorkflowPageControls";
import { Flex, Heading } from "@aws-amplify/ui-react";
import AccentDescription from "../components/general/AccentDescription";
import TutorialPopup from "../components/general/TutorialPopup";
import { Button } from "@mui/material";
import { Button as AmplifyButton } from "@aws-amplify/ui-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dialog from "../components/general/Dialog";
import { useTranslation } from "react-i18next";

/**
 * Represents the RecordingPage component.
 * 
 * @param {Object} props - The component props.
 * @param {string} [props.accentType=null] - The accent type.
 * @param {Object} [props.invitation=null] - The invitation object.
 * @returns {JSX.Element} The rendered RecordingPage component.
 */
const RecordingPage = ({accentType = null, invitation = null}) => {
    const navigation = useNavigate();
    const {t} = useTranslation(["Common", "RecordingPage"]);
    const {formState, validateFormData} = useContextData();
    const location = useLocation();
    const _accentType = accentType || location.state?.accentType;
    const [clickedOk, setClickedOk] = useState(false);
    const [isFinishDialogOpen, setIsFinishDialogOpen] = useState(false);
 
    useEffect(() => {
        if (Object.keys(formState).length === 0) {
            // form data missing
            navigation('/');
            return;
        }

        if (!validateFormData(_accentType)) {
            // form data invalid (may have been edited by user via browser forward/back buttons)
            console.log("form data invalid")
            navigation('/accentform', {state: {accentType: _accentType}});
        }
    }, [])

    return (
        <WorkflowPageControls 
            style={invitation ? {display: "none"} : {}}
            ForwardButton={
                <AmplifyButton
                    type="submit"
                    variation="link"
                    className='submit'
                    onClick={() => setIsFinishDialogOpen(true)}
                    style={{border: "none", color: "black"}}
                >
                    <Flex margin={5} justifyContent={"center"} alignItems={"center"}>
                        {t("Finish")} <FontAwesomeIcon cursor="pointer" icon="arrow-right" size="2x"/>
                    </Flex>
                    <Dialog
                        open={isFinishDialogOpen}
                        onClose={() => {
                            setIsFinishDialogOpen(false)
                        }}
                        title={t("Finish")} 
                        text={t("FinishUnsavedProgress")}
                        yesFn={() => {
                            navigation("/recordings")
                        }}
                        noFn={() => {
                            setIsFinishDialogOpen(false)
                        }}
                    />
                </AmplifyButton>
            }
        >
            {Object.keys(formState).length !== 0 &&
                <Flex className="recording-page" direction={"column"}>
                    <TutorialPopup videoName={_accentType === "regional" ? "regionalAccentPg2" : "fantasyAccentPg2"}/>
                    <AccentDescription data={formState} type={_accentType}/>
                    {clickedOk 
                        ? 
                        <Recorder/>
                        :
                        <Flex textAlign={"center"} direction="column">
                            <Heading whiteSpace={"pre-line"} style={{textWrap: "balance"}} textAlign={"center"} level={5}>
                                {t("RecordingPage:Intro")}
                            </Heading>
                        </Flex>
                    }
                    {!clickedOk && <Button variant="outlined" onClick={() => setClickedOk(true)}>Ok</Button>}
                </Flex>
            }
        </WorkflowPageControls>
    )
}

export default RecordingPage;