/**
 * Initial state for regional form data.
 * @type {Object}
 * @property {string} country - The country value.
 * @property {string} state - The state value.
 * @property {string} area - The area value.
 */
export const initialRegionalState = {
    country: '',
    state: '',
    area: ''
}

/**
 * Initial state for fantasy form data.
 * @type {Object}
 * @property {string} race - The race value.
 */
export const initialFantasyState = {
    race: ''
}

/**
 * Initial state for common form data.
 * @type {Object}
 * @property {string} ownerId - The owner ID value.
 * @property {string} AccentType - The accent type value.
 * @property {string[]} keywords - The keywords array.
 * @property {string} gender - The gender value.
 * @property {string} age - The age value.
 * @property {string} emotion - The emotion value.
 */
export const initialCommonState = {
    ownerId: '',
    AccentType: '',
    keywords: [],
    gender: '',
    age: '',
    emotion: 'none'
}

/**
 * Initial state for accent form error.
 * @type {Object}
 * @property {boolean} valid - The validity flag.
 */
export const initialAccentFormErrorState = {
    valid: true
}

/**
 * Initial state for accent form data.
 * @type {Object}
 * @property {string} country - The country value.
 * @property {string} state - The state value.
 * @property {string} area - The area value.
 * @property {string} race - The race value.
 * @property {string} ownerId - The owner ID value.
 * @property {string} AccentType - The accent type value.
 * @property {string[]} keywords - The keywords array.
 * @property {string} gender - The gender value.
 * @property {string} age - The age value.
 * @property {string} emotion - The emotion value.
 */
export const initialAccentFormDataState = {
    ...initialCommonState,
    ...initialRegionalState,
    ...initialFantasyState
};

/**
 * Maximum number of text boxes for keywords.
 * @type {number}
 */
export const maxTextBoxes = 10;

/**
 * Character limit for keywords.
 * @type {number}
 */
export const keywordCharLimit = 100;

/**
 * Character limit for district.
 * @type {number}
 */
export const districtCharLimit = 100;

/**
 * Character limit for email.
 * @type {number}
 */
export const emailCharLimit = 320;

/**
 * Character limit for name or description.
 * @type {number}
 */
export const nameOrDescCharLimit = 150;