import { DataGrid } from '@mui/x-data-grid';
import "../../styles/recordingsPage.scss"

/**
 * Renders a table component with data for columns and rows.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.columns - The array of column definitions.
 * @param {Array} props.rows - The array of row data.
 * @param {Function} [props.isRowSelected] - The function to determine if a row is selected.
 * @param {Function} [props.onRowSelected] - The function to handle row selection.
 * @param {ReactNode} [props.header] - The custom header component.
 * @returns {JSX.Element} The rendered AccentTable component.
 */
const AccentTable = ({columns, rows, isRowSelected = (e) => {}, onRowSelected = (e) => {}, header = null, ...props}) => {
    const onRowSelectionModelChange = (e) => {
        if (e.length > 0) {
            isRowSelected(true)
        }
        else {
            isRowSelected(false)
        }
    }
    
    return (
        <div className='accent-table-container'>
            <DataGrid
                rows={rows} 
                columns={columns}
                slots={{
                    toolbar: header
                }}
                onRowSelectionModelChange={onRowSelectionModelChange}
                onRowDoubleClick={(e) => onRowSelected(e.row)}
                {...props} 
            />
        </div>
    )
}

export default AccentTable;