import RecordingsPartial from "./partials/RecordingsPartial";

/**
 * Renders the UserRecordingsPage component.
 * @returns {JSX.Element} The rendered UserRecordingsPage component.
 */
const UserRecordingsPage = () => {
    return <RecordingsPartial type={"cards"}/>
}

export default UserRecordingsPage;