import { API, graphqlOperation } from "aws-amplify";
import { createRegionalAccent, deleteRegionalAccent } from "../graphql/mutations";
import { listRegionalAccents } from "../graphql/customQueries";
import { delAccentRecording } from "./RecordingService";

/**
 * Posts a regional accent to the database.
 * @param {Object} data - The data of the regional accent to be posted.
 * @param {string} accentId - The ID of the regional accent.
 * @param {...any} options - Additional options for the API call.
 */
async function postRegionalAccent(data, accentId, ...options) {
    try {
        await API.graphql(graphqlOperation(createRegionalAccent, {...options, input: data }));
        console.log("successfully uploaded to DB");
    } catch (err) {
        console.error(err);
        await delAccentRecording(accentId);
    }
}

/**
 * Retrieves regional accents from the database.
 * @param {Object} options - Additional options for the API call.
 * @returns {Promise<Object>} A promise that resolves to the retrieved regional accents.
 */
async function getRegionalAccents({...options} = {}) {
    try {
        const data = await API.graphql(graphqlOperation(listRegionalAccents, {...options}));
        return data;
    } catch (err) {
        console.error(err);
    }
}

/**
 * Retrieves regional accents from the database based on status and filter.
 * @param {string|null} status - The status of the regional accents to retrieve. If null, retrieves all regional accents.
 * @param {Object} filter - The filter to apply to the retrieval.
 * @param {...any} options - Additional options for the API call.
 * @returns {Promise<Object>} A promise that resolves to the retrieved regional accents.
 */
async function getRegionalAccentsByStatus(status = null, filter = {}, ...options) {
    if (status === null) { 
        return await getRegionalAccents({...options, filter});
    }

    return await getRegionalAccents({
        ...options,
        filter: { status: { eq: status }, ...filter},
    });
}

/**
 * Deletes a regional accent from the database.
 * @param {string} key - The primary key of the regional accent to delete.
 * @returns {Promise<boolean>} A promise that resolves to true if the deletion is successful, false otherwise.
 */
async function delRegionalAccent(key) {
    try {
        await API.graphql(graphqlOperation(deleteRegionalAccent, {input: { PrimaryKey: key }}));
        console.log("regional accent record successfully deleted from DB");
        return true;
    }
    catch (err) {
        console.error(err);
        return false;
    }
}

export {
    postRegionalAccent,
    getRegionalAccents,
    getRegionalAccentsByStatus,
    delRegionalAccent
}