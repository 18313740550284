import React, { useEffect } from 'react';
import CountryRegionSelection from './CountryRegionSelect';
import { useContextData } from '../../context/DataContext';
import { useTranslation } from 'react-i18next';
import AccentForm from './AccentForm';
import AccentFormFieldContainer from './AccentFormFieldContainer';
import { Flex } from '@aws-amplify/ui-react';
import LimitedTextField from '../general/LimitedTextField';
import { districtCharLimit, emailCharLimit, nameOrDescCharLimit } from '../../constants/accentFormDataConstants';
import { isValidEmail } from '../../helpers/text';

/**
 * A form component for capturing regional accent information.
 *
 * @component
 * @param {boolean} [isForAnotherUser=false] - Indicates whether the form is for another user.
 * @param {Function} [setFamilyFriendsState=()=>{}] - A function to update the state of family and friends information.
 * @param {Object} [familyFriendsErrorsState={}] - The state object containing errors for family and friends information.
 * @returns {JSX.Element} The rendered RegionalAccentForm component.
 */
const RegionalAccentForm = ({isForAnotherUser = false, setFamilyFriendsState = () => {}, familyFriendsErrorsState = {}}) => {
  const {t} = useTranslation(["Common", "HomePage"])
  const { updateDistrict } = useContextData();

  const handleDistrictChange = (event) => {
    // Access the selected value from the event object
    const selectedDistrict = event.target.value;
    // Update the state with the selected value
    updateDistrict(selectedDistrict);
  };

  return (
    <AccentForm type="regional" isForAnotherUser={isForAnotherUser}>
      {isForAnotherUser &&
        <>
          <AccentFormFieldContainer>
            <Flex direction="column" gap="small">
              <label htmlFor="Name or relationship description" className="block text-gray-700">{t("NameOrRelationDesc")}*</label>
              <LimitedTextField
                id="name-or-relation-desc"
                required
                limit={nameOrDescCharLimit}
                placeholder={t("Common:EnterThing", {thing: t("NameOrRelationDesc")})} 
                onChange={(e) => {setFamilyFriendsState(prev => ({...prev, nameOrRelationDesc: e.target.value}))}}
                hasError={familyFriendsErrorsState?.nameOrRelationDesc}
              />
            </Flex>
          </AccentFormFieldContainer>

          <AccentFormFieldContainer>
            <Flex direction="column" gap="small">
              <label htmlFor="their-email" className="block text-gray-700">{t("TheirEmail")}*</label>
              <LimitedTextField
                id="their-email"
                required
                limit={emailCharLimit}
                placeholder={t("Common:EnterThing", {thing: t("TheirEmail").toLowerCase()})} 
                onChange={(e) => {setFamilyFriendsState(prev => ({...prev, email: e.target.value}))}}
                validator={isValidEmail}
                hasError={familyFriendsErrorsState?.email}
              />
            </Flex>
          </AccentFormFieldContainer>
        </>
      }
      <AccentFormFieldContainer>
        <CountryRegionSelection/>
      </AccentFormFieldContainer>
      <AccentFormFieldContainer>
        <Flex direction="column" gap="small">
          <label htmlFor="district" className="block text-gray-700">{t("District")}</label>
          <LimitedTextField
            id="area"
            limit={districtCharLimit}
            placeholder={t("Common:EnterThing", {thing: t("District")})} 
            onChange={handleDistrictChange}
          />
        </Flex>
      </AccentFormFieldContainer>
    </AccentForm>
  );
};

export default RegionalAccentForm;
