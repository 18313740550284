import AWS from "aws-sdk";
import { API, graphqlOperation } from "aws-amplify";
import { createAccentRecording, deleteAccentRecording } from "../graphql/mutations";
import { delRegionalAccent, postRegionalAccent } from "../services/RegionalAccentService"
import { delFantasyAccent, postFantasyAccent } from "../services/FantasyAccentService";

AWS.config.update({
    region: 'eu-central-1', // e.g. 'us-west-1'
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const s3 = new AWS.S3();
const recordingBucket = "accentrecrods";
const recordingsPrefix = "recordings/";

/**
 * Saves audio blob to S3 bucket.
 * @param {Blob} blob - The audio blob to be saved.
 * @param {string} fileName - The name of the file.
 * @returns {Promise<Object>} - A promise that resolves to the S3 upload result.
 */
async function saveAudioToS3(blob, fileName) {
    const params = {
        Bucket: recordingBucket,
        Key: `${recordingsPrefix}${fileName}.mp3`, // You can modify this as you wish
        Body: blob,
        ContentType: 'audio/mp3',
    };

    try {
        const result = await s3.upload(params).promise();
        return result;
    } catch (err) {
        console.error("Error uploading audio to S3:", err);
    }

    return;
}

/**
 * Posts an accent recording to the API.
 * @param {Object} accentRecordingData - The data of the accent recording.
 * @param {Object} data - The data associated with the accent recording.
 * @param {string} accentId - The ID of the accent.
 * @returns {Promise<boolean>} - A promise that resolves to true if the post operation is successful, false otherwise.
 */
async function postAccentRecording(accentRecordingData, data, accentId) {
    try {
        await API.graphql(graphqlOperation(createAccentRecording, { input: accentRecordingData }));

        if (accentRecordingData.Type === "regional") {
            await postRegionalAccent(data, accentId);
        } else {
            await postFantasyAccent(data, accentId);
        }

        return true;
    } catch (err) {
        console.error(err);
    }

    return false;
}

/**
 * Deletes an accent recording.
 * @param {string} type - The type of the accent recording.
 * @param {string} accentId - The ID of the accent.
 */
async function delAccentRecording(type, accentId) {
    const params = {
        Bucket: recordingBucket,
        Key: `${recordingsPrefix}${accentId}.opus`,
    }

    try {
        await API.graphql(graphqlOperation(deleteAccentRecording, { input: { AccentId: accentId } }));

        if (type === "Regional") {
            await delRegionalAccent(accentId);
        } else {
            await delFantasyAccent(accentId);
        }

        s3.deleteObject(params, (err, res) => {
            if (err) {
                console.error(err);
                return;
            }
        })

        console.log("Deleted recording successfully");
    } catch (err) {
        console.error("Error deleting accent recording:", err);
    }
}

export {
    saveAudioToS3,
    postAccentRecording,
    delAccentRecording
}