import { Autocomplete } from "@aws-amplify/ui-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

/**
 * A component for selecting values using autocomplete.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} [props.isSelected=false] - Indicates whether the value is selected.
 * @param {function} [props.onChange=(e) => {}] - The callback function triggered when the value changes.
 * @param {function} [props.onSelect=(e) => {}] - The callback function triggered when a value is selected.
 * @param {function} [props.onClear=() => {}] - The callback function triggered when the value is cleared.
 * @param {*} props.value - The current value.
 * @param {Array} props.options - The available options for selection.
 * @param {string|null} [props.placeholder=null] - The placeholder text for the input field.
 * @param {boolean} [props.labelHidden=false] - Indicates whether the label is hidden.
 * @param {string|null} [props.descriptiveText=null] - The descriptive text for the component.
 * @param {boolean} [props.disabled=false] - Indicates whether the component is disabled.
 * @returns {JSX.Element} The rendered AccentAutocomplete component.
 */
const AccentAutocomplete = ({
    isSelected = false,
    onChange = (e) => {},
    onSelect = (e) => {},
    onClear = () => {},
    value,
    options,
    placeholder = null,
    labelHidden = false,
    descriptiveText = null,
    disabled = false,
    ...props
}) => {
    return (
        <Autocomplete
            value={value}
            options={options}
            placeholder={placeholder}
            labelHidden={labelHidden}
            descriptiveText={descriptiveText}
            disabled={disabled}
            onChange={onChange}
            onSelect={onSelect}
            onClear={onClear}
            outerEndComponent={
                <FontAwesomeIcon
                    style={{paddingLeft: 15}}
                    size="xl"
                    icon={isSelected ? "check" : "hand-pointer"}
                    color={isSelected ? "green" : "gray"}
                />
            }
            {...props}
        />
    )
}

export default AccentAutocomplete;