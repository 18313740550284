import React, { useRef } from "react";
import micSvg from "../../assets/mic.svg"
import micRedoSvg from "../../assets/mic_redo.svg" 
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { useContextData } from "../../context/DataContext";
import { v4 as uuidv4 } from "uuid";
import { initialCommonState, initialRegionalState, initialFantasyState } from "../../constants/accentFormDataConstants";
import { useEffect, useState } from "react";
import { Button, Flex, Heading, useAuthenticator } from "@aws-amplify/ui-react";
import { LiveAudioVisualizer } from "react-audio-visualize";
import 'react-h5-audio-player/src/styles.scss'
import "../../styles/recording.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RecorderDialogs from "./RecorderDialogs";
import AudioPlayback from "./AudioPlayback";
import { RaceUnderReview, UnderReview, maxLocalRecordings } from "../../constants/recordingConstants";
import LocalRecordingsList from "./LocalRecordingsList";
import Transcript from "./Transcript";
import {postAccentRecording, saveAudioToS3} from "../../services/RecordingService";
import { useNavigate } from "react-router-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { API, graphqlOperation } from "aws-amplify";
import { isRecordingStatusCountOverLimit } from "../../graphql/customQueries";
import { Skeleton, Button as MUIButton, CircularProgress, Box, Typography } from "@mui/material";
import LocalRecordingsDialog from "../general/FullscreenDialog";
import { decodeUriString } from "../../helpers/text";
import { useTranslation } from "react-i18next";
import { emotionOptions } from "./EmotionSelect";
import Dialog from "../general/Dialog";

const emotionEmojis = {
  none: "😐",
  happy: "😁",
  sad: "😢",
  fear: "😨",
  surprise: "😮",
  disgust: "🤢",
  anger: "😡"
}

/**
 * Recorder component for recording audio and saving recordings.
 * 
 * This component is responsible for recording audio, saving the recordings, and managing the state of the recordings.
 * It uses the react-audio-voice-recorder library for recording audio and the react-h5-audio-player library for audio playback.
 * The component also includes functionality for displaying a transcript and managing local recordings.
 * 
 * @component
 * @param {Object} invitation - The invitation object for the recording, which is optional.
 * @returns {JSX.Element} The rendered Recorder component.
 */
const Recorder = ({invitation = {}}) => {
  const navigation = useNavigate();
  const {t} = useTranslation(["Common", "RecordingPage"]);
  const recorderControls = useAudioRecorder();
  const minute = 60;
  const maxRunningTime = 2 * minute;
  const audioPlayerRef = useRef();

  const [isLoading, setIsLoading] = useState(true);
  const {user} = useAuthenticator((context) => [context.user]);
  const {formState, isNewRace, resetInputs} = useContextData();
  const { updateEmotion } = useContextData();
  const [dataToSubmit, setDataToSubmit] = useState({});
  const [recordings, setRecordings] = useState([]);
  const [blob, setBlob] = useState();
  const [audioSourceURL, setAudioSourceURL] = useState("");
  const [audioLength, setAudioLength] = useState(0);
  const [countdownActive, setCountdownActive] = useState(false);
  const [transcriptTitle, setTranscriptTitle] = useState("");
  const [transcriptFilename, setTranscriptFilename] = useState("")
  const [isLocalRecordingsDialogOpen, setIsLocalRecordingsDialogOpen] = useState(false);
  const [isSaveRecordingDialogOpen, setIsSaveRecordingDialogOpen] = useState(false);
  const [isPostSaveDialogOpen, setIsPostSaveDialogOpen] = useState(false);
  const [isUnsavedRecordingDialogOpen, setIsUnsavedRecordingDialogOpen] = useState(false)
  const [isNextMessageEnabled, setIsNextMessageEnabled] = useState(false);
  const [hasSavedOneRecording, setHasSavedOneRecording] = useState(false)

  const [transcriptProgress, setTranscriptProgress] = useState([])
  const [transcriptProgressFinished, setTranscriptProgressFinished] = useState(undefined);
  const [emotionsProgress, setEmotionsProgress] = useState({none: {val: 0, saved: false}});
  const [emotionsComplete, setEmotionsComplete] = useState(false)
  const [emotionSelected, setEmotionSelected] = useState()
  const [isPlaybackEnabled, setIsPlaybackEnabled] = useState(false);
  const audioRef = useRef(null);

  const getInitialRecordingStatus = () => {
    if (formState.AccentType === "fantasy" && isNewRace) {      
      return RaceUnderReview;
    }

    return UnderReview;
  }

  const audioLengthStringRepr = (timeInSeconds) => {
    let hours = Math.floor(timeInSeconds / 3600);
    let minutes = Math.floor((timeInSeconds - (hours * 3600)) / 60);
    let seconds = timeInSeconds - (hours * 3600) - (minutes * 60);

    if (seconds < 10) {
      seconds = seconds < 10 ? "0" + seconds : seconds;
    }

    if (hours >= 1) {
      hours = hours + ":"
    }
    else {
      hours = ""
    }

    return `${hours}${minutes}:${seconds}`;
  }

  const onRecordingComplete = (newBlob) => {
    if (transcriptProgressFinished) {
      const url = URL.createObjectURL(newBlob);
      setBlob(newBlob);
      setAudioSourceURL(url);

      const recording = {blob: newBlob, url: url, length: audioLengthStringRepr(audioLength), transcriptProgress: transcriptProgress};
      const currentRecordings = [...recordings]
      currentRecordings.push(recording);
  
      if (currentRecordings.length >= maxLocalRecordings) {
        setIsLocalRecordingsDialogOpen(true)
      }
  
      setRecordings(currentRecordings)
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
    else if (blob) {
      setEmotionsProgress(prev => ({
        ...prev,
        [emotionSelected]: {
          val: 100
        }
      }))
    }
    else {
      setEmotionsProgress(prev => ({
        ...prev,
        [emotionSelected]: {
          val: 0
        }
      }))
    }
  };

  const selectRecordingHandler = (recording) => {
    if (recording.url === audioSourceURL) {
      console.log(audioPlayerRef.current.audio.current.paused)
      if (audioPlayerRef.current.audio.current.paused) {
        audioPlayerRef.current.audio.current.play();
        return;
      }

      audioPlayerRef.current.audio.current.pause();
    }
    setBlob(recording.blob);
    setAudioSourceURL(recording.url);
  }

  const deleteLocalRecordingHandler = (index) => {
    const currentRecordings = [...recordings];
    if (currentRecordings[index].url === audioSourceURL) {
      setAudioSourceURL("");
      setBlob();
    }
    currentRecordings.splice(index, 1);

    setRecordings(currentRecordings);
  }

  const saveRecording = async () => {
    // Ensure the audioSourceURL is not empty
    console.log("Save recording triggered");
    
    if (!audioSourceURL) return;

    if (audioLength > maxRunningTime) {
      alert("Audio length must not exceed one minute!");
      return;
    }

    const guid = uuidv4();
    const accentId = `${formState.ownerId}_${guid}`;
    const result = await saveAudioToS3(blob, accentId);

    if (result !== null) {
      let audioURL = result.Location.replace(/\.[^.]*$/, '.opus'); // our postprocessing lambda will change our audio file to .opus

      setDataToSubmit(prev => ({...prev, PrimaryKey: accentId, status: getInitialRecordingStatus(), recordingLength: audioLengthStringRepr(audioLength)}))
      
      const {["AccentType"]: omitKey, ...data} = {
        ...dataToSubmit,
        PrimaryKey: accentId,
        email: invitation?.senderEmail || user.attributes.email,
        status: getInitialRecordingStatus(),
        recordingLength: audioLengthStringRepr(audioLength),
        ReferredByUserId: "test"
      }

      // now that we have the audio url, upload recording metadata to DB
      const accentRecordingData = {
        AccentId: accentId,
        Type: formState.AccentType,
        recordingProgression: recordings[recordings.length - 1].transcriptProgress,
        transcriptFileName: transcriptFilename,
        audioURL: audioURL,
        length: audioLengthStringRepr(audioLength),
      }

      const status = postAccentRecording(accentRecordingData, data, accentId)

      // reset prior states
      resetInputs();

      setEmotionSelected(null)
      setIsNextMessageEnabled(true)
      setHasSavedOneRecording(true)
      setEmotionsProgress(prev => ({
        ...prev,
        [formState.emotion]: {
          val: 100,
          saved: true
        }
      }))
      return status;
    }

    return false;
  };
  
  const playback = async () => {
    if (recordings.length === 0 || recorderControls.isRecording || isPlaybackEnabled) {
      return;
    }

    const currentRecording = recordings[recordings.length - 1]
    if (currentRecording !== undefined) {
      let audio = new Audio(currentRecording.url)
      audio.play()

      audioRef.current = audio
      setIsPlaybackEnabled(true)
    }
  }

  const countdown = async (start) => {
    setCountdownActive(start)
    while (start > 0) {
      await new Promise((resolve) => {
        setTimeout(() => {
          start -= 1;
          setCountdownActive(start);
          resolve(); // Resolve the promise after the timeout
        }, 1000); // Wait for 1000ms (1 second)
      });
    }
    
    recorderControls.startRecording()
    setCountdownActive(false)
  }

  useEffect(() => {
    return () => {
      if (audioRef.current !== null) {
        audioRef.current.pause()
        audioRef.current = null
      }
    }
  }, [])

  useEffect(() => {
      // exclude any data that doesn't belong to that type of accent
      let data = {...initialCommonState, ...(formState.AccentType === "regional" ? initialRegionalState : initialFantasyState)}
      Object.keys(data).forEach(key => {
          data[key] = formState[key];
      });

      setDataToSubmit(prev => ({...prev, ...data}));

      setIsLoading(false);
  }, [formState])

  useEffect(() => {
      if (audioSourceURL !== "") {
        // when beginning to record, muting audio player programatically doesn't seem to work, heres an alternative for now
        setAudioSourceURL("");
      }

      if (recorderControls.recordingTime === 0) return;
      
      setAudioLength(recorderControls.recordingTime)
      
      if (recorderControls.recordingTime >= maxRunningTime) {
        recorderControls.stopRecording();
      }
  }, [recorderControls.recordingTime])

  useEffect(() => {
    const micOrSaveIcon = document.querySelector('.audio-recorder-mic');
    const audioTimer = document.querySelector('.audio-recorder-timer')
    const audioPause = document.querySelector('[data-testid="ar_pause"]')
    
    if (recorderControls.isRecording) {
      window.scrollTo({top: 0, left: 0, behavior: "smooth"})
      setTranscriptProgressFinished(false)
      setEmotionsProgress(prev => ({
        ...prev,
        [emotionSelected]: {
          val: 0
        }
      }))
      if (micOrSaveIcon) {
        micOrSaveIcon.style.display = "none"
        audioTimer.insertAdjacentElement("beforebegin", audioPause)
      }
    }
    else {
      if (micOrSaveIcon)
        micOrSaveIcon.style.display = ""
    }

    const recordingStatus = document.querySelector('.audio-recorder-status');

    if (recordingStatus) {
        recordingStatus.innerHTML = `<span class="audio-recorder-status-dot"></span>${t("Recording")}`;
    }
  }, [recorderControls.isRecording])

  useEffect(() => {
    setAudioSourceURL("");
    setAudioLength(0)
    setRecordings([])
    setTranscriptProgress([])
    setTranscriptProgressFinished(undefined)
    setBlob()
  }, [formState.emotion])

  useEffect(() => {
    if (transcriptProgressFinished) {
      setEmotionsProgress(prev => ({
        ...prev,
        [formState.emotion]: {
          val: 100
        }
      }))
    }
  }, [transcriptProgressFinished])

  useEffect(() => {
    setEmotionsComplete(
      Object.keys(emotionOptions).every(emotion => {
        return emotionsProgress[emotion] && typeof emotionsProgress[emotion] === 'object' && emotionsProgress[emotion].saved === true
      })
    )
  }, [emotionsProgress])

  return (
    <>
      {!isNextMessageEnabled &&
        <Transcript
          classes={audioSourceURL && !recorderControls.isRecording && !isPlaybackEnabled && "display-none"}
          data={formState} 
          isRecording={recorderControls.isRecording}
          playback={isPlaybackEnabled}
          setIsPlaybackEnabled={setIsPlaybackEnabled}
          onFinish={() => {
            setTranscriptProgressFinished(true)
            recorderControls.stopRecording()
          }}
          onSentenceChange={(time, stepProgress) => { 
            setTranscriptProgress(time)
            setEmotionsProgress(prev => (
              {
                ...prev, 
                [formState.emotion]: {
                  ...prev[formState.emotion],
                  val: stepProgress
                }
              }
            ))
          }}
          onTranscriptFilenameChange={setTranscriptFilename}
          onTitleChange={setTranscriptTitle}
        />
      }

      {/* <AudioPlayback
          style={{position: "fixed"}}
          ref={audioPlayerRef}
          autoPlay={false}
          autoPlayAfterSrcChange={false}
          muted={recorderControls.isRecording}
          url={recordings.length != 0 ? audioSourceURL : ""}
      /> */}
      <Flex justifyContent={"center"}>
          <Flex direction={"column"} width={"100%"} justifyContent={"center"} alignItems={"center"}>
            {isNextMessageEnabled && 
              <Flex direction={"column"} alignItems={"center"} justifyContent={"center"} marginTop={50}>
                <Heading whiteSpace={"pre-line"} style={{textWrap: "balance"}} textAlign={"center"} level={4}>
                  {!emotionsComplete 
                    ?
                    <span>
                      {t("RecordingPage:SavedRecording")}
                    </span>
                    :
                    <span>
                      {t("RecordingPage:FinishedRecordings")}
                    </span>
                  }
                </Heading>
                <Flex>
                  <MUIButton
                    disabled={!emotionSelected && !emotionsComplete}
                    onClick={() => {
                      if (!emotionsComplete) {
                        setIsNextMessageEnabled(false) 
                      }
                      else {
                        navigation("/recordings")
                      }                    
                    }}
                    style={{marginTop: 50}}
                    width={"fit-content"} 
                    alignItems={"center"}
                    alignSelf={"center"}
                    justifyContent={"space-evenly"}
                    color={!emotionsComplete ? "primary" : "success"}
                    variant={!emotionsComplete ? "outlined" : "contained"}
                  >
                    {!emotionsComplete ?
                      t("Ok")
                      :
                      t("Finish")
                    }
                  </MUIButton>
                </Flex>
              </Flex>
            }

            <Flex alignItems={"center"} justifyContent={"center"}>
              {!recorderControls.isRecording && audioSourceURL !== "" && !isNextMessageEnabled & !isPlaybackEnabled ? (
                  <FontAwesomeIcon
                    color={recordings.length === 0 || isPlaybackEnabled ? "lightgray" : "black"}
                    onClick={playback}
                    cursor={"pointer"}
                    icon="circle-play"
                    fontSize={"5em"}
                  />
              ) : null}
              {!isNextMessageEnabled && !isPlaybackEnabled &&
                <Flex direction={"column"}>
                  {countdownActive ?
                    <span
                      class={"audio-recorder audio-recorder-mic"}
                    >
                      {countdownActive}
                    </span>
                    :                   
                    <img 
                      class={"audio-recorder audio-recorder-mic"}
                      src={audioSourceURL !== "" ? micRedoSvg : micSvg}
                      onClick={() => {
                        countdown(3)
                      }}
                    />
                  }
                  
                  <AudioRecorder
                    key={"audioRecorder"}
                    classes={{
                      AudioRecorderClass: !recorderControls.isRecording && "display-none",
                      AudioRecorderStartSaveClass: "display-none",
                      AudioRecorderPauseResumeClass: "display-none",
                      AudioRecorderDiscardClass: "display-none"
                    }}
                    onRecordingComplete={onRecordingComplete}
                    recorderControls={recorderControls}
                    downloadFileExtension="mp3"
                    audioTrackConstraints={{ channelCount: 1 }}
                    showVisualizer={true}
                  />
                </Flex>
              }
            </Flex>
            
            <Flex wrap={"wrap"} justifyContent={"center"} marginTop={15}>
              {hasSavedOneRecording && Object.keys(emotionOptions).map((emotion) =>
                <Flex direction={"column"} alignItems={"center"}>
                  <span 
                    className={`${recorderControls.isRecording || emotionsProgress?.[emotion]?.saved === true ? "" : "selectable-emoji"} emoji`}
                    onClick={() => {
                      if (formState.emotion !== emotion && !recorderControls.isRecording && !emotionsProgress?.[emotion]?.saved) {
                        setEmotionSelected(emotion)
                        if (!isNextMessageEnabled && blob !== undefined) {
                          setIsUnsavedRecordingDialogOpen(true)
                        }
                        else {
                          updateEmotion(emotion)
                        }
                      }
                    }}
                  >
                    {emotionEmojis[emotion]}
                  </span>
                  
                  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    <CircularProgress 
                      variant="determinate" 
                      color={emotionsProgress?.[emotion]?.saved ? "success" : "primary"} 
                      value={emotionsProgress?.[emotion]?.val ?? 0} 
                    />
                    <Box
                      sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        className="user-select"
                        variant="caption"
                        component="div"
                        sx={{ color: emotionSelected === emotion ? '#0012d9' : 'text.secondary' }}
                      >
                        {`${Math.round(emotionsProgress?.[emotion]?.val ?? 0 )}%`}
                      </Typography>
                    </Box>
                  </Box>
                </Flex>
              )}
            </Flex>
            
            <Flex width={"100%"} wrap={"wrap-reverse"} alignItems={"center"} justifyContent={"center"}>
              {audioSourceURL !== "" && !isNextMessageEnabled && !recorderControls?.isRecording && !isPlaybackEnabled &&
                <Button
                  disabled={recordings.length == 0}
                  onClick={() => {
                      if (recorderControls.isRecording) return;
                      setIsSaveRecordingDialogOpen(true);
                  }}
                  width={"100%"}
                  minWidth={270}
                  alignItems={"center"}
                  alignSelf={"center"}
                  justifyContent={"center"}
                  borderColor={"red"}
                >
                  <FontAwesomeIcon style={{marginRight: 5}} icon={"floppy-disk"}></FontAwesomeIcon>
                  {t("Next")}
                </Button>
              }
            </Flex>
          </Flex>
          
        </Flex>

      <Dialog
        open={isUnsavedRecordingDialogOpen}
        onClose={setIsUnsavedRecordingDialogOpen}
        title={t("UnsavedRecording")}
        text={t("FinishUnsavedProgress")}
        yesFn={() => {
          setEmotionsProgress(prev => ({
            ...prev,
            [formState.emotion]: {
              val: 0
            }
          }))
          updateEmotion(emotionSelected)
        }}
        noFn={() => {
          setEmotionSelected(formState.emotion)
        }}
      />

      <RecorderDialogs
        onSaveRecording={saveRecording}
        isSaveRecordingDialogOpen={isSaveRecordingDialogOpen}
        setIsSaveRecordingDialogOpen={setIsSaveRecordingDialogOpen}
        isPostSaveDialogOpen={isPostSaveDialogOpen}
        setIsPostSaveDialogOpen={setIsPostSaveDialogOpen}
      />
    </>
  );
};

export default Recorder;
