import { Flex, Text } from "@aws-amplify/ui-react";
import { muiGridColumnStyle } from "../constants/muiGridColumnStyle";
import {
    LeadingActions,
    SwipeableList,
    SwipeableListItem,
    SwipeAction,
    TrailingActions,
  } from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import "../styles/swipeableTableCell.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContextData } from "../context/DataContext";
import { getGeneralStatus, getNewStatusOnAction, onReviewHandler } from "./recordings";


// for admins

const CustomLeadingActions = ({status, onAction}) => {
    const nextStatus = getNewStatusOnAction(status, false);

    return (
      <LeadingActions style={{height: "100%"}}>
        <SwipeAction onClick={() => {onAction(nextStatus)}}>
          <Flex justifyContent={"center"} alignItems={"center"}>
            <FontAwesomeIcon title={nextStatus} icon={"xmark"}/>
            <Text color="white">{nextStatus}</Text>
          </Flex>
        </SwipeAction>
      </LeadingActions>
    )
};
  
const CustomTrailingActions = ({status, onAction}) => {
    const nextStatus = getNewStatusOnAction(status, true);

    return (
      <TrailingActions style={{height: "100%"}}>
        <SwipeAction onClick={() => {onAction(nextStatus)}}>
          <Flex justifyContent={"center"} alignItems={"center"}>
            <FontAwesomeIcon title={nextStatus} icon={"check"}/>
            <Text color="white">{nextStatus}</Text>
          </Flex>
        </SwipeAction>
      </TrailingActions>
    );
}
 
export const onStatusCellActionHandler = (nextStatus, data, gridAPIRef) => {
  const status = onReviewHandler(data.PrimaryKey, nextStatus, data.accentType);
  
  // update cell locally
  if (status) {
    gridAPIRef.current.updateRows([{id: data.id, status: nextStatus}])
  }
}

const StatusCell = ({gridAPIRef, ...props}) => {
    const {isAdmin} = useContextData();
    props.value = isAdmin ? props.value : getGeneralStatus(props.value);

    const {value} = props;
    const rowData = {
      ...props.row,
      accentType: props.row.race ? "Fantasy" : "Regional",
    }

    const handleOnAction = (nextStatus) => {
      onStatusCellActionHandler(nextStatus, rowData, gridAPIRef);
    }

    return (
      <>
        {isAdmin ? 
          <SwipeableList>
            <SwipeableListItem 
              style={{height: "100%"}} 
              leadingActions={<CustomLeadingActions status={value} onAction={handleOnAction}/>}
              trailingActions={<CustomTrailingActions status={value} onAction={handleOnAction}/>}
            >
              {value}
            </SwipeableListItem>
          </SwipeableList>
          : value
        }
      </>
    )
}

// users / admins

export function formTableData(data, gridAPIref) {
    if (!data || !data.length) return { columns: [], rows: [] };

    const maxWidthOfStatus = 165;
    const columns = Object.keys(data[0]).map((key) => {
        return { 
          field: key, 
          headerName: key, 
          renderCell: key === "status" ? (props) => <StatusCell gridAPIRef={gridAPIref} {...props}/> : null, 
          ...muiGridColumnStyle, 
          ...(key === "status" && {maxWidth: key === "status" ? maxWidthOfStatus : null}),
        };
    });

    const rows = data.map((row) => ({ id: row.id, ...row }));

    return { columns, rows };
}
