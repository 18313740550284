import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useContextData } from "../../context/DataContext";
import { changeLanguage } from 'i18next';
import { I18n } from 'aws-amplify';
import { initialAccentFormErrorState } from "../../constants/accentFormDataConstants";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { queryIsRecordingStatusOverLimit } from "../../services/UtilsService";
import AccentSnackBar from "./AccentSnackBar";

/**
 * AppWrapper component is a wrapper component that provides common functionality and state management for the entire application.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components to be rendered inside the AppWrapper.
 * @returns {ReactNode} The rendered AppWrapper component.
 */
const AppWrapper = ({children}) => {
    const navigation = useNavigate();
    const location = useLocation();
    const {legalAgreementAccepted, setLegalAgreementAccepted} = useContextData();
    const {user, authStatus} = useAuthenticator((context) => [context.user, context.authStatus]);
    const {setIsAdmin, selectedSiteLanguage, setSelectedSiteLanguage, setFormErrorState, resetInputs} = useContextData();
    const [isLoading, setIsLoading] = useState(true);
    const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
    const allowedPathsIfTooManyRecordingsUnderReview = new Set([
        "/recordings",
        "/account",
        "/auth",
        "/language"
    ])
    const allowedPathsIfNoLegalAgreement = new Set([
        "/auth",
        "/account",
        "/language"
    ])
    
    const loadLanguageFromCookie = () => {
        const lang = selectedSiteLanguage; // the cookie is already loaded in context data if available, otherwise null
        if (lang) {
            console.log("Language: ", lang)
            setSelectedSiteLanguage(lang);
        }
        else {
            // no cookie was set
            navigation("/language");
        }
    }

    useEffect(() => {
        // do work with site data here once the app loads
        loadLanguageFromCookie();
    }, [])

    useEffect(() => {
        if (user) {
            // check if admin
            const groups = user?.signInUserSession?.idToken?.payload["cognito:groups"] ?? []
            const isAdmin = groups.includes("Admin");
            setIsAdmin(isAdmin);

            // check if legal agreement is accepted
            const agreedToLegal = user.attributes?.["custom:agreedToLegal"]
            if (agreedToLegal === "true") {
                setLegalAgreementAccepted(true)
            }
            else {
                setLegalAgreementAccepted(false)
            }
        }

        if (authStatus !== "configuring") {
            setIsLoading(false);
        }
    }, [user, authStatus])

    useEffect(() => {
        // Change language from app level once set
        if (selectedSiteLanguage) {
          changeLanguage(selectedSiteLanguage);
          I18n.setLanguage(selectedSiteLanguage); // set language for Amplify components like Authenticator  
        }
    }, [selectedSiteLanguage])

    useEffect(() => {
        if (authStatus === "authenticated") {
            // make sure they have accepted the legal agreement if moving around the website authenticated
            if (!legalAgreementAccepted) {
                if (location.pathname !== "/legal-agreement" && !allowedPathsIfNoLegalAgreement.has(location.pathname)) {
                    navigation("/legal-agreement", {state: {nextLocation: location.pathname}})
                }
            }

            // too many recordings under review
            if (!allowedPathsIfTooManyRecordingsUnderReview.has(location.pathname)) {
                queryIsRecordingStatusOverLimit().then(res => {
                    if (res.data.isRecordingStatusCountOverLimit) {
                        navigation("/recordings");
                        setIsSnackBarOpen(true);
                    }
                }).catch(err => {
                    console.error(err);
                })
            }
        }

    }, [location, authStatus])

    return (
        <>
        {isLoading ? null : 
            <>
                {children}
                <AccentSnackBar open={isSnackBarOpen} message="Too many recordings are pending review! Please delete a pending recording or wait until an administrator reviews one."/>
            </>
        }
        </>
    )
}

export default AppWrapper;