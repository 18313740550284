import Cookies from 'js-cookie';
import AWS from "aws-sdk";

AWS.config.update({
    region: 'eu-central-1', // e.g. 'us-west-1'
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const s3 = new AWS.S3();
const tutorialsBucket = "accenttutorials";
const file = "tutorial_videos.json";

/**
 * Represents a TutorialService that provides access to tutorials.
 */
class TutorialService {
    /**
     * Creates a singleton instance of TutorialService.
     * @constructor
     */
    constructor() {
        if (TutorialService.instance) {
            return TutorialService.instance;
        }

        this.tutorials = this.loadTutorials();
        TutorialService.instance = this;
    }

    /**
     * Loads tutorials from an external source.
     * @returns {Promise<Object>} A promise that resolves to the loaded tutorials.
     */
    async loadTutorials() {
        const params = {
            Bucket: tutorialsBucket,
            Key: file,
        };

        try {
            const data = await s3.getObject(params).promise();
            const fileContent = data.Body.toString('utf-8');
            return JSON.parse(fileContent);
        } catch (err) {
            console.error("Error getting file from S3:", err);
            return {};
        }
    }

    /**
     * Appends the language suffix to the given name based on the selected language.
     * @param {string} name - The name to append the language suffix to.
     * @returns {string} The name with the language suffix.
     */
    nameLang(name) {
        this.language = Cookies.get("language");
        this.languageSuffix = this.language && this.language !== "en" ? "_" + this.language : "";

        return name + this.languageSuffix;
    }

    /**
     * Retrieves a specific tutorial by name.
     * @param {string} name - The name of the tutorial to retrieve.
     * @returns {Promise<Object>} A promise that resolves to the requested tutorial.
     */
    async getTutorial(name) {
        const data = await this.tutorials;
        return data[this.nameLang(name)];
    }

    /**
     * Retrieves all tutorials.
     * @returns {Promise<Object>} A promise that resolves to all the tutorials.
     */
    async getTutorials() {
        return this.tutorials;
    }
}

export default TutorialService;
