import en from "./locales/en/translations.json";
import es from "./locales/es/translations.json";
import { initReactI18next } from 'react-i18next';
import i18n from "i18next";

// TODO: In future, do lazy loading of translations
i18n
.use(initReactI18next) // passes i18n down to react-i18next
.init({
    resources: {en, es},
    lng: "en",
    fallbackLng: "en",
    ns: ["Common", "AccentAttributes", "HomePage"],
    interpolation: {
    escapeValue: false
    }
});


export { i18n };