import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft, faArrowRight, faBars, faCaretDown, faCheck, faCirclePlay, faDragon, faFloppyDisk, faGlobe, faHandPointer, faMicrophoneLines, faPenToSquare, faPeopleArrows, faQuestionCircle, faSquareMinus, faSquarePlus, faTag, faTrashCan, faX, faXmark } from '@fortawesome/free-solid-svg-icons';

export function globalFontAwesomeIconsInit() {
    library.add(faArrowLeft);
    library.add(faArrowRight)
    library.add(faSquarePlus);
    library.add(faTrashCan);
    library.add(faCheck);
    library.add(faXmark);
    library.add(faHandPointer)
    library.add(faCirclePlay);
    library.add(faTag);
    library.add(faMicrophoneLines);
    library.add(faFloppyDisk);
    library.add(faBars);
    library.add(faDragon);
    library.add(faGlobe);
    library.add(faPeopleArrows);
    library.add(faBars);
    library.add(faPenToSquare);
    library.add(faX);
    library.add(faQuestionCircle)
    library.add(faCaretDown)
}
