export function strNewlinesJSX(string) {
    return (
        string.split('\n').map((line, index) => (
            <span key={index}>
                {line}
                <br />
            </span>
        ))
    )
}

export const decodeUriString = (string) => {
    if (!string) return;

    return decodeURIComponent(string.replace(/\+/g, " "));
}


/**
  * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
  * 
  * @param {String} text The text to be rendered.
  * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
  * 
  * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
  */
export function getTextWidth(text, font) {
    // re-use canvas object for better performance
    const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
}

export function isValidEmail(email) {
    const regex = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;
    return regex.test(email);
}