import { SelectField } from '@aws-amplify/ui-react';
import { useContextData } from '../../context/DataContext';
import { useTranslation } from 'react-i18next';

/**
 * Renders a gender selection component intended for accent forms.
 *
 * @returns {JSX.Element} The gender selection component.
 */
const GenderSelection = () => {
   const {t} = useTranslation(["AccentAttributes", "Common", "HomePage"])
   const { selectedGender, updateGender, formErrorState } = useContextData();
   const options = {
    male: t("Common.Gender.Male"),
    female: t("Common.Gender.Female"),
    other: t("Common.Gender.Other")
   };

   const handleGenderChange = (event) => {
    // Access the selected value from the event object
    const selectedGender = event.target.value;
    
    // Update the state with the selected value
    updateGender(selectedGender);
  };

  return (
    <div>
      <SelectField
        isRequired
        hasError={!formErrorState?.valid && !formErrorState?.gender}
        label={`${t("HomePage:Gender")}*`}
        descriptiveText={t("Common:PleaseSelectTheThing", {thing: t("HomePage:Gender")})}
        onChange={handleGenderChange}
        value={selectedGender}>
            <option value="">- {t("Common:SelectOne")} -</option>
            {Object.keys(options).map((option) => (
              <option key={option} value={option}>{options[option]}</option>
            ))}
        </SelectField>
    </div>
  );
}

 export default GenderSelection;