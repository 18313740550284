import React from 'react';
import FantasyRaceSelection from './FantasyRaceSelect';
import AccentForm from './AccentForm';
import AccentFormFieldContainer from './AccentFormFieldContainer';

/**
 * Renders a form for fantasy accents.
 * @returns {React.Component} The rendered fantasy accent form.
 */
const FantasyAccentForm = () => {
  return (
    <AccentForm type="fantasy">
      <AccentFormFieldContainer>
        <FantasyRaceSelection/>
      </AccentFormFieldContainer>
    </AccentForm>
  );
};

export default FantasyAccentForm;
