import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {i18n} from './i18n';
import AWS from 'aws-sdk';
import { Authenticator, translations, ThemeProvider } from '@aws-amplify/ui-react';
import { Amplify, I18n } from 'aws-amplify';
import awsExports from './aws-exports';
import { globalFontAwesomeIconsInit } from './icons';
import i18nCountries from "i18n-iso-countries";
import enCountries from "i18n-iso-countries/langs/en.json";
import esCountries from "i18n-iso-countries/langs/es.json";

// Configure the AWS SDK
AWS.config.update({
  region: 'eu-central-1', // e.g. 'us-west-1'
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

Amplify.configure(awsExports);
I18n.putVocabularies(translations); // translations for Amplify components e.g. Authenticator, see i18n.js for general translations
i18nCountries.registerLocale(enCountries);
i18nCountries.registerLocale(esCountries);

globalFontAwesomeIconsInit();
const amplifyUITheme = {
  name: "amplify-theme",
  tokens: {
    components: {
      button: {
        backgroundColor: "white"
      }
    }
  }
} 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( // StrictMode causes two re-renders for debugging reasons, only use during development
  // <React.StrictMode>
    <Authenticator.Provider>
      <ThemeProvider theme={amplifyUITheme}>
        <App />
      </ThemeProvider>
    </Authenticator.Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
