import React, { createContext, useState, useContext } from 'react';
import Cookies from 'js-cookie';
import { initialAccentFormErrorState, districtCharLimit, initialAccentFormDataState, initialCommonState, initialFantasyState, initialRegionalState, keywordCharLimit, maxTextBoxes } from '../constants/accentFormDataConstants';
import { useAuthenticator } from '@aws-amplify/ui-react';

const DataContext = createContext();

/**
 * DataProvider component provides a context for managing and sharing data across the application.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components to be wrapped by the DataProvider.
 * @returns {ReactNode} The wrapped child components.
 */
export const DataProvider = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [legalAgreementAccepted, setLegalAgreementAccepted] = useState(false)
  const [selectedSiteLanguage, setSelectedSiteLanguage] = useState(Cookies.get("language") || null);
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedRace, setSelectedRace] = useState('');
  const [selectedAgeProfile, setSelectedAgeProfile] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedEmotion, setSelectedEmotion] = useState('none');
  const [isNewRace, setIsNewRace] = useState(false);
  const [district, setDistrict] = useState('');
  const [textBoxes, setTextBoxes] = useState(['']);
  const [formState, setFormState] = useState({});
  const [formErrorState, setFormErrorState] = useState(initialAccentFormErrorState);

  const updateGender = (gender) => {
    setSelectedGender(gender);
    setFormState(prev => ({ ...prev, gender: gender }));
  };

  const updateRace = (race) => {
    setSelectedRace(race);
    setFormState(prev => ({ ...prev, race: race }));
  }

  const updateAgeProfile = (age) => {
    setSelectedAgeProfile(age);
    setFormState(prev => ({ ...prev, age: age }));
  };

  const updateCountry = (country) => {
    setSelectedCountry(country);
    setFormState(prev => ({ ...prev, country: country }))
  };

  const updateRegion = (region) => {
    setSelectedRegion(region);
    setFormState(prev => ({ ...prev, state: region }))
  };

  const updateDistrict = (district) => {
    setDistrict(district);
    setFormState(prev => ({ ...prev, area: district }))
  }
  
  const updateEmotion = (emotion) => {
    setSelectedEmotion(emotion);
    setFormState(prev => ({ ...prev, emotion: emotion }))
  }

  const resetInputs = () => {
    setSelectedGender('');
    setSelectedRace('');
    setSelectedAgeProfile('');
    setSelectedCountry('');
    setSelectedRegion('');
    setSelectedEmotion('');
    setDistrict('');
    setTextBoxes(['']);
    setIsNewRace(false);
  }

  const handleTextBoxChange = (index, value) => {
    const updatedTextBoxes = [...textBoxes];
    if (updatedTextBoxes.length > 1 && value === "") {
      updatedTextBoxes.splice(index, 1);
    }
    else {
      updatedTextBoxes[index] = value;
    }

    setTextBoxes(updatedTextBoxes);
    setFormState(prev => ({ ...prev, keywords: updatedTextBoxes }))
  };

  const handleAddTextBox = (limit = null) => {
    if (textBoxes.length + 1 > maxTextBoxes) {
      return;
    }

    if (textBoxes[textBoxes.length - 1] === "") {
      return;
    }

    if (limit && textBoxes[textBoxes.length - 1].length > limit) {
      return;
    }

    setTextBoxes([...textBoxes, '']);
    setFormState(prev => ({ ...prev, keywords: textBoxes }))
  };

  const handleDeleteTextBox = (index) => {
    const updatedTextBoxes = [...textBoxes];
    updatedTextBoxes.splice(index, 1);
    setTextBoxes(updatedTextBoxes);
    setFormState(prev => ({ ...prev, keywords: updatedTextBoxes }))
  }

  const isFormStateValid = (state) => {
    // basic form verification
    const optional = ["keywords", "emotion", "state", "area"]

    const stateKeys = Object.keys(state);
    const areaIsNotOverCharLimit = formState.area?.length <= districtCharLimit;
    const keywordsAreNotOverCharLimit = formState.keywords.every(keyword => keyword.length <= keywordCharLimit);
    const otherValidations = [areaIsNotOverCharLimit, keywordsAreNotOverCharLimit];
    
    const isValid = stateKeys.every(key => optional.includes(key) || formState[key] !== null && formState[key] !== "") && otherValidations.every(validation => validation);

    // for highlighting required fields not filled
    const formErrorStateObj = stateKeys.reduce((acc, key) => {
        acc[key] = optional.includes(key) || (formState[key] !== null && formState[key] !== "");
        return acc;
    }, {});


    setFormErrorState(prev => ({...prev, ...formErrorStateObj}))
    return isValid;
  }
  
  const validateFormData = (accentType) => {
    let accentTypeInitialState;
    if (accentType === "regional") {
        accentTypeInitialState = initialRegionalState;
    } else {
        accentTypeInitialState = initialFantasyState;
    }

    const commonValidation = isFormStateValid(initialCommonState);
    const accentTypeValidation = isFormStateValid(accentTypeInitialState);
    if (!commonValidation || !accentTypeValidation) {
        setFormErrorState(prev => ({...prev, valid: false}));
        return false;
    }
    
    return true;
  }

  return (
    <DataContext.Provider value={{ isAdmin, setIsAdmin, legalAgreementAccepted, setLegalAgreementAccepted, selectedSiteLanguage, setSelectedSiteLanguage, selectedGender, updateGender, selectedRace, updateRace, selectedAgeProfile, updateAgeProfile, 
      selectedCountry, updateCountry, selectedRegion, updateRegion, district, updateDistrict, selectedEmotion, updateEmotion,
      textBoxes, handleTextBoxChange, handleAddTextBox, handleDeleteTextBox, formState, setFormState, formErrorState, setFormErrorState, isNewRace, setIsNewRace, validateFormData, resetInputs}}>
      {children}
    </DataContext.Provider>
  );
};

export const useContextData = () => {
  return useContext(DataContext);
};
