/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      id
      name
      language
      description
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTodos = /* GraphQL */ `
  query ListTodos(
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        language
        description
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRegionalAccent = /* GraphQL */ `
  query GetRegionalAccent($PrimaryKey: String!) {
    getRegionalAccent(PrimaryKey: $PrimaryKey) {
      id
      PrimaryKey
      status
      ownerId
      email
      keywords
      gender
      age
      emotion
      country
      state
      area
      recordingLength
      ReferredByUserId
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRegionalAccents = /* GraphQL */ `
  query ListRegionalAccents(
    $PrimaryKey: String
    $filter: ModelRegionalAccentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRegionalAccents(
      PrimaryKey: $PrimaryKey
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        PrimaryKey
        status
        ownerId
        email
        keywords
        gender
        age
        emotion
        country
        state
        area
        recordingLength
        ReferredByUserId
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFantasyAccent = /* GraphQL */ `
  query GetFantasyAccent($PrimaryKey: String!) {
    getFantasyAccent(PrimaryKey: $PrimaryKey) {
      id
      PrimaryKey
      status
      race
      emotion
      ownerId
      email
      keywords
      gender
      age
      recordingLength
      ReferredByUserId
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFantasyAccents = /* GraphQL */ `
  query ListFantasyAccents(
    $PrimaryKey: String
    $filter: ModelFantasyAccentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFantasyAccents(
      PrimaryKey: $PrimaryKey
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        PrimaryKey
        status
        race
        emotion
        ownerId
        email
        keywords
        gender
        age
        recordingLength
        ReferredByUserId
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAccentRecording = /* GraphQL */ `
  query GetAccentRecording($AccentId: String!) {
    getAccentRecording(AccentId: $AccentId) {
      id
      AccentId
      Type
      transcriptFileName
      recordingProgression
      audioURL
      length
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAccentRecordings = /* GraphQL */ `
  query ListAccentRecordings(
    $AccentId: String
    $filter: ModelAccentRecordingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAccentRecordings(
      AccentId: $AccentId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        AccentId
        Type
        transcriptFileName
        recordingProgression
        audioURL
        length
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAccentTranscript = /* GraphQL */ `
  query GetAccentTranscript($id: ID!) {
    getAccentTranscript(id: $id) {
      id
      emotion
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAccentTranscripts = /* GraphQL */ `
  query ListAccentTranscripts(
    $filter: ModelAccentTranscriptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccentTranscripts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        emotion
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAccentReferral = /* GraphQL */ `
  query GetAccentReferral($refereeId: String!) {
    getAccentReferral(refereeId: $refereeId) {
      refereeId
      referralId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAccentReferrals = /* GraphQL */ `
  query ListAccentReferrals(
    $refereeId: String
    $filter: ModelAccentReferralFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAccentReferrals(
      refereeId: $refereeId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        refereeId
        referralId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAccentRecordingInvitation = /* GraphQL */ `
  query GetAccentRecordingInvitation($id: ID!) {
    getAccentRecordingInvitation(id: $id) {
      id
      userId
      senderEmail
      recipientEmail
      recipientDesc
      accentParams
      expires
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAccentRecordingInvitations = /* GraphQL */ `
  query ListAccentRecordingInvitations(
    $filter: ModelAccentRecordingInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccentRecordingInvitations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        senderEmail
        recipientEmail
        recipientDesc
        accentParams
        expires
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const isRecordingStatusCountOverLimit = /* GraphQL */ `
  query IsRecordingStatusCountOverLimit($status: String!, $userId: String) {
    isRecordingStatusCountOverLimit(status: $status, userId: $userId)
  }
`;
