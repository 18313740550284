import React, { useEffect, useState } from 'react';
import { Button, Flex } from '@aws-amplify/ui-react';
import { useTranslation } from 'react-i18next';
import RegionalAccentForm from '../components/forms/RegionalAccentForm';
import FantasyAccentForm from '../components/forms/FantasyAccentForm';
import DynamicTextBoxes from '../components/forms/DynamicTextBoxes';
import { useContextData } from '../context/DataContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import {districtCharLimit, emailCharLimit, initialAccentFormDataState, initialAccentFormErrorState, initialCommonState, initialFantasyState, initialRegionalState, keywordCharLimit, nameOrDescCharLimit} from "../constants/accentFormDataConstants";
import WorkflowPageControls from '../components/general/WorkflowPageControls';
import "../styles/accentForm.scss"
import AccentFormFieldContainer from '../components/forms/AccentFormFieldContainer';
import { Alert } from '@mui/material';
import { isValidEmail, strNewlinesJSX } from '../helpers/text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withAppNavbar from '../components/general/withAppNavbar';
import TutorialPopup from '../components/general/TutorialPopup';
import { sendRecordingInvitation } from '../services/UtilsService';

/**
 * Represents the Accent Form page component which can be used for regional, fantasy accent, etc.
 *
 * @returns {JSX.Element} The Accent Form page component.
 */
const AccentFormPage = () => {
    const navigation = useNavigate();
    const location = useLocation();
    const accentType = location.state?.accentType;
    const isForAnotherUser = location.state?.isForAnotherUser;
    const tutorialName = isForAnotherUser ? "familyFriendsAccentPg1" : accentType === "regional" ? "regionalAccentPg1" : "fantasyAccentPg1"
    const {formState, setFormState, formErrorState, setFormErrorState, validateFormData} = useContextData();
    const {authStatus, user} = useAuthenticator((context) => [context.authStatus, context.user]);
    const {t} = useTranslation(["HomePage", "Common"]);
    const [isValidating, setIsValidating] = useState(false);
    const [familyFriendsState, setFamilyFriendsState] = useState({})
    const [familyFriendsErrorsState, setFamilyFriendsErrorsState] = useState({})

    const onFormSubmitHandler = async () => {
        const generalValidation = validateFormData(accentType)
        const isFriendsFamilyEmailInvalid = !isValidEmail(familyFriendsState?.email) || familyFriendsState?.email?.length > emailCharLimit
        const isFriendsFamilyNameOrDescInvalid = familyFriendsState?.nameOrRelationDesc?.length > nameOrDescCharLimit
        
        if (isForAnotherUser && isFriendsFamilyEmailInvalid || isFriendsFamilyNameOrDescInvalid) {
            setFamilyFriendsErrorsState({email: isFriendsFamilyEmailInvalid, nameOrRelationDesc: isFriendsFamilyNameOrDescInvalid})
            return false;
        }

        // in general
        if (!generalValidation) {
            return false;
        }

        // if not for family/friends
        if (!isForAnotherUser) {
            navigation("/recorder", {state: {accentType: accentType}});
        }
        else {
            // family/friends data validated, send email
            const res = await sendRecordingInvitation({familyFriendsState: familyFriendsState, formState: formState})
            console.log("sent", res)
        }
    }

    const NextButton = ({children, style}) => {
        return (
            <Button
                type="submit"
                variation="link"
                className='submit'
                style={style}
                isLoading={isValidating}
                loadingText={`${t("Common:Validating")}...`}
                onClick={() => {
                    setIsValidating(true);
                    setTimeout(() => {
                        const isFormValid = onFormSubmitHandler();
                        if (!isFormValid) {
                            // scroll to the top of the form page
                            window.scrollTo(0, 0);
                        }
                        setIsValidating(false);
                    }, 500);
                }}
            >
                {children}
            </Button>
        )
    }

    useEffect(() => {
        if (Object.keys(formState) == 0) {
            setFormState({...initialAccentFormDataState, AccentType: accentType})
        }

        return () => {
            setFormErrorState(initialAccentFormErrorState)
        }
    }, [])

    useEffect(() => {
        if (authStatus === "authenticated") {
            const userId = user.attributes.sub;
            setFormState(prev => ({...prev, ownerId: userId}));
        }
    }, [authStatus])

    useEffect(() => {
        if (!accentType) {
            // user accessed route directly without choosing accent type in home page
            navigation('/');
        }

        setFormState(prev => ({...prev, AccentType: accentType}))
    }, [accentType])

    return (
        <WorkflowPageControls 
            ForwardButton={
                <NextButton style={styles.topNextButton}>
                    <Flex margin={5} justifyContent={"center"} alignItems={"center"}>
                        {isForAnotherUser ? t("Common:SendEmail") : t("Common:Next")} <FontAwesomeIcon cursor="pointer" icon="arrow-right" size="2x"/>
                    </Flex>
                </NextButton>
            }
        >
            <Flex alignItems={"center"} justifyContent={"center"}>
                <TutorialPopup videoName={tutorialName} />
            </Flex>
            <Flex as="form" className="accent-form container">
                {formErrorState.valid === false && formErrorState.valid !== undefined && 
                    <Alert severity='error'>
                        {strNewlinesJSX(t("Common:PleaseFillRequiredFields"))}
                    </Alert>
                }
                {accentType === 'regional' ? (
                    <>
                        <RegionalAccentForm 
                            isForAnotherUser={isForAnotherUser} 
                            setFamilyFriendsState={setFamilyFriendsState}
                            familyFriendsErrorsState={familyFriendsErrorsState}
                        />
                        <AccentFormFieldContainer>
                            <DynamicTextBoxes keywords={formState.keywords}/>
                        </AccentFormFieldContainer>
                    </>
                ) :
                (
                    <>
                        <FantasyAccentForm />
                        <AccentFormFieldContainer>
                            <DynamicTextBoxes/>
                        </AccentFormFieldContainer>
                    </>
                )}
                <NextButton style={styles.bottomNextButton}>
                    {isForAnotherUser ? t("Common:SendEmail") : t("Common:Next")}
                </NextButton>
            </Flex>
        </WorkflowPageControls>
    )
}

const styles = {
    todo: {  marginBottom: 15 },
    input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
    todoName: { fontSize: 20, fontWeight: 'bold' },
    todoDescription: { marginBottom: 0 },
    topNextButton: { border: "none", color: "black" },
    bottomNextButton: { width: "100%", backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px' }
}

export default withAppNavbar(AccentFormPage);