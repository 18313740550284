import { CheckboxField, Flex, TextField } from '@aws-amplify/ui-react';
import { useContextData } from '../../context/DataContext';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import AccentAutocomplete from '../general/AccentAutocomplete';

/**
 * Renders a component for selecting a fantasy race in an accent form.
 *
 * @component
 * @example
 * // Usage:
 * <FantasyRaceSelection />
 *
 * @returns {JSX.Element} The rendered component.
 */
const FantasyRaceSelection = () => {
  const {t} = useTranslation(["AccentAttributes", "Common", "HomePage"]);
  const options = [
    {"id": "elf", "label": t("Fantasy.Race.Elf")},
    {"id": "orc", "label": t("Fantasy.Race.Orc")},
    {"id": "dwarf", "label": t("Fantasy.Race.Dwarf")},
    {"id": "goblin", "label": t("Fantasy.Race.Goblin")},
    {"id": "witch", "label": t("Fantasy.Race.Witch")},
    {"id": "wizard", "label": t("Fantasy.Race.Wizard")}
  ];
  const { selectedRace, updateRace, isNewRace, setIsNewRace, formErrorState } = useContextData();
  const [existingRaceValue, setExistingRaceValue] = useState((!isNewRace && selectedRace?.charAt(0).toUpperCase() + selectedRace?.slice(1)) || "");
  const [newRaceValue, setNewRaceValue] = useState((isNewRace && selectedRace) || "");

  const handleFantasyRaceChange = (event, setValue) => {
    const race = event?.id || event.target.value;
    setValue(event?.label || event.target.value)

    updateRace(race);
  };

  const onValueChange = (event, setValue) => {
    updateRace("");
    setValue(event.target.value)
  }
  
  const onValueClear = (setValue) => {
    updateRace("");
    setValue("");
  }

  return (
    <Flex direction={"column"}>
      <AccentAutocomplete
        isSelected={selectedRace && selectedRace !== ""}
        disabled={isNewRace}
        isRequired
        hasError={!isNewRace && !formErrorState?.valid && !formErrorState?.race}
        placeholder={t("Common:SelectOne")}
        label={t("HomePage:Race") + "*"}
        labelHidden={false}
        descriptiveText={t("Common:PleaseSelectTheThing", {thing: t("HomePage:Race")})}
        onChange={(e) => {onValueChange(e, setExistingRaceValue)}}
        onSelect={(e) => {handleFantasyRaceChange(e, setExistingRaceValue)}}
        onClear={() => {onValueClear(setExistingRaceValue)}}
        value={existingRaceValue}
        options={options}
      />

      <CheckboxField 
        name="race"
        label={t("Common:Question", {question: `${t("Common:Create")} ${t("HomePage:Race")}`})} 
        checked={isNewRace} 
        onChange={(e) => {
          setIsNewRace(e.target.checked);
          onValueClear(setNewRaceValue);
          onValueClear(setExistingRaceValue);
        }}
      />
      
      <TextField
        disabled={!isNewRace}
        name="newRace"
        placeholder={t("Common:NewRace")}
        value={newRaceValue}
        onChange={(e) => {handleFantasyRaceChange(e, setNewRaceValue)}} 
        isRequired={isNewRace}
        hasError={isNewRace && !formErrorState?.valid && !formErrorState?.race}
      />
    </Flex>
  );
}

export default FantasyRaceSelection;