import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTextAnalyzer } from 'use-text-analyzer';

const TranscriptSentence = ({text, onAnalysis, isPlaying = false, onFinishPlaying = () => {}}) => {
    const {readingTime} = useTextAnalyzer({text, wordsPerMinute: 125})
    const words = text.split(" ")
    const [timePerWord, setTimePerWord] = useState(0)
    const [highlightedIndex, setHighlightedIndex] = useState(0)
    
    const startTransition = async () => {
        let currentHighlightedIndex = highlightedIndex
        while (currentHighlightedIndex <= words.length) {
            await new Promise((resolve) => {
                setTimeout(() => {
                  currentHighlightedIndex++;
                  setHighlightedIndex(currentHighlightedIndex)
                  resolve(); // Resolve the promise after the timeout
                }, timePerWord); 
            });
        }
    }

    useEffect(() => {
        setTimePerWord((readingTime.seconds * 1000) / words.length)
        onAnalysis(readingTime)
    }, [readingTime])

    useEffect(() => {
        if (isPlaying) {
            startTransition()
        }
        else {    
            setHighlightedIndex(0) // reset
        }
    }, [isPlaying])

    useEffect(() => {
        if (highlightedIndex > words.length) {
            onFinishPlaying()
        }
    }, [highlightedIndex])

    return (
        <Typography variant="h4" flexWrap={"wrap"}>
            {words.map((word, index) => (
                <>
                    <span 
                        key={index} 
                        style={
                            isPlaying && highlightedIndex === index 
                            ? {backgroundColor: "yellow"} 
                            : 
                            index < highlightedIndex
                            ? {color: "lightgray"}
                            : {}
                        }
                    >
                        {word}
                    </span>
                    {index < words.length - 1 && " "}
                </>
            ))}
        </Typography>
    )
}

export default TranscriptSentence;