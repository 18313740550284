/**
 * Renders the Admin Recording Approval Page.
 * This page gives admins a table view of users' recordings.
 * @returns {JSX.Element} The rendered Admin Recording Approval Page.
 */
import RecordingsPartial from "./partials/RecordingsPartial";

const AdminRecordingApprovalPage = () => {
    return <RecordingsPartial type="grid"/>
}

export default AdminRecordingApprovalPage;