import { useLocation, useNavigate } from "react-router-dom";
import Dialog from "../general/Dialog";
import { useEffect, useState } from "react";
import EmotionSelection, { emotionOptions } from "./EmotionSelect";
import { useContextData } from "../../context/DataContext";
import { useTranslation } from "react-i18next";

/**
 * Represents the dialog component for the recorder page used after recording.
 *
 * @component
 * @example
 * return (
 *   <RecorderDialogs
 *     onSaveRecording={handleSaveRecording}
 *     isSaveRecordingDialogOpen={isSaveRecordingDialogOpen}
 *     setIsSaveRecordingDialogOpen={setIsSaveRecordingDialogOpen}
 *     isPostSaveDialogOpen={isPostSaveDialogOpen}
 *     setIsPostSaveDialogOpen={setIsPostSaveDialogOpen}
 *   />
 * )
 */
const RecorderDialogs = ({onSaveRecording, isSaveRecordingDialogOpen = null, setIsSaveRecordingDialogOpen, isPostSaveDialogOpen = null, setIsPostSaveDialogOpen}) => {
    const {t} = useTranslation(["RecorderDialogs"])
    const location = useLocation()
    const navigation = useNavigate();
    const [wasActionTaken, setWasActionTaken] = useState(false);
    const [isEmotionsDialogOpen, setIsEmotionsDialogOpen] = useState(false)
    const [excludedEmotions, setExcludedEmotions] = useState(["none"]);
    const {formState} = useContextData()

    useEffect(() => {
        // none should have been recorded at this point
        setExcludedEmotions(["none"])
    }, [location])

    return (
        <>
            <Dialog
                open={isSaveRecordingDialogOpen}
                onClose={() => {
                    setIsSaveRecordingDialogOpen(false)
                    setWasActionTaken(false)
                }}
                title={t("SaveSelectedRecording")} 
                text={t("AreYouSureYouWantToSelectThisRecording")}
                yesFn={async () => {
                    setWasActionTaken(true)
                    const status = await onSaveRecording();
                    if (status) {
                        // setIsPostSaveDialogOpen(true);
                    }
                    else {
                        alert(t("ErrorSavingRecording"))
                    }
                }}
                noFn={() => {
                    setWasActionTaken(true)
                    setIsSaveRecordingDialogOpen(false)
                }}
            />

            <Dialog
                open={isPostSaveDialogOpen}
                onClose={() => {
                    if (!wasActionTaken)
                        setIsPostSaveDialogOpen(true)
                    else {
                        setIsPostSaveDialogOpen(false)
                        setWasActionTaken(false)
                    }
                }}
                title={t("WhatNow")} 
                text={Object.keys(emotionOptions).length === excludedEmotions.length ? t("RecordAnotherAccent") : t("RecordTheAccentWithAEmotion")}
                yesFn={() => {
                    setWasActionTaken(true)
                    setTimeout(() => {
                        if (Object.keys(emotionOptions).length === excludedEmotions.length) {
                            navigation("/");
                            return;
                        }
                        
                        setExcludedEmotions(prev => [...prev, formState.emotion])
                        setIsPostSaveDialogOpen(false);
                        setIsEmotionsDialogOpen(true);
                    }, 500)
                }}
                noFn={() => {
                    setWasActionTaken(true)
                    setTimeout(() => {
                        navigation("/recordings");
                    }, 500)
                }}
            />

            <Dialog
                open={isEmotionsDialogOpen}
                onClose={() => {
                    if (!wasActionTaken)
                        setIsEmotionsDialogOpen(true)
                    else {
                        setIsEmotionsDialogOpen(false)
                        setWasActionTaken(false);
                    }
                }}
                title={t("PleaseSelectAnEmotion")}
                content={<EmotionSelection excluding={excludedEmotions}/>}
                yesFn={() => {
                    setWasActionTaken(true)
                    setTimeout(() => {
                        setIsEmotionsDialogOpen(false)
                        window.scroll({
                            top: 0,
                            behavior: 'smooth'
                        });
                    }, 500)
                }}
            />
        </>
    )
}

export default RecorderDialogs;