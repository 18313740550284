import {API, graphqlOperation} from 'aws-amplify';
import {isRecordingStatusCountOverLimit, sendRecordingInvitationMutation} from "../graphql/customQueries";

/**
 * Queries whether the recording status is over the limit.
 * @param {string} status - The recording status (default: "UnderReview").
 * @returns {Promise} - A promise that resolves with the result of the GraphQL operation.
 */
async function queryIsRecordingStatusOverLimit(status = "UnderReview") {
    return API.graphql(graphqlOperation(isRecordingStatusCountOverLimit, {status: status}));
}

/**
 * Sends a recording invitation.
 *
 * @param {Object} options - The options for sending the invitation.
 * @param {Object} options.familyFriendsState - The state of the family and friends.
 * @param {Object} options.formState - The state of the form.
 * @returns {Promise} A promise that resolves when the invitation is sent.
 */
async function sendRecordingInvitation({familyFriendsState, formState}) {
    return API.graphql(graphqlOperation(sendRecordingInvitationMutation, {recipientDetails: JSON.stringify(familyFriendsState), accentData: JSON.stringify(formState)}))
}

export {
    queryIsRecordingStatusOverLimit,
    sendRecordingInvitation
}