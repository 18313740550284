export const listRegionalAccents = /* GraphQL */ `
  query ListRegionalAccents(
    $PrimaryKey: String
    $filter: ModelRegionalAccentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRegionalAccents(
      PrimaryKey: $PrimaryKey
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        PrimaryKey
        email
        status
        country
        keywords
        gender
        age
        emotion
        state
        area
      }
    }
  }
`;

export const listRegionalAccentsByStatus = `
query ListRegionalAccentsByStatus($limit: Int, $status: String!) {
    listRegionalAccents(limit: $limit, filter: {status: {eq: $status}}) {
        items {
          id
          PrimaryKey
          email
          status
          country
          keywords
          gender
          age
          emotion
          state
          area
        }
    }
}  
`;

export const listFantasyAccents = /* GraphQL */ `
  query ListFantasyAccents(
    $PrimaryKey: String
    $filter: ModelFantasyAccentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFantasyAccents(
      PrimaryKey: $PrimaryKey
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        PrimaryKey
        email
        status
        race
        emotion
        keywords
        gender
        age
      }
    }
  }
`;

export const listFantasyAccentsByStatus = `
query ListFantasyAccentsByStatus($limit: Int, $status: String!) {
    listFantasyAccents(limit: $limit, filter: {status: {eq: $status}}) {
        items {
          id
          PrimaryKey
          email
          status
          race
          emotion
          keywords
          gender
          age
        }
    }
}  
`;

export const listAccentRecordings = /* GraphQL */ `
  query ListAccentRecordings(
    $AccentId: String
    $filter: ModelAccentRecordingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAccentRecordings(
      AccentId: $AccentId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        AccentId
        Type
        audioURL
        length
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const isRecordingStatusCountOverLimit = `
query GetIsRecordingStatusCountOverLimit($status: String!) {
  isRecordingStatusCountOverLimit(status: $status)
}
`;

export const listTranscriptsByEmotion = `
  query ListTranscriptsByEmotion($emotion: String) {
    listAccentTranscripts(filter: {emotion: {eq: $emotion}}) {
      items {
        id
        title
        emotion
      }
    }
  }
  
`

export const sendRecordingInvitationMutation = `
  mutation SendRecordingInvitation($recipientDetails: AWSJSON!, $accentData: AWSJSON!) {
    sendRecordingInvitation(recipientDetails: $recipientDetails, accentData: $accentData)
  }
`

export const getRecordingProgression = `
  query getRecordingProgression($AccentId: String!) {
    getAccentRecording(AccentId: $AccentId) {
      recordingProgression
    }
  }
`