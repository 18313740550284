import { Button, Card, CardActions, CardContent, CardHeader, capitalize } from "@mui/material"
import KeywordsChips from "./KeywordsChips";
import NoKeywords from "./NoKeywords";
import { Flex, Text } from "@aws-amplify/ui-react";
import { getGeneralStatus, getGeneralStatusColor } from "../../helpers/recordings";
import { useTranslation } from "react-i18next";

/**
 * UserRecordingCard component displays a card containing information about a user recording.
 * It takes the following props:
 * @param {string} type - The type of recording (e.g., "Regional").
 * @param {object} data - The data object containing information about the recording.
 * @param {function} onSelect - The function to be called when the card is selected.
 * 
 * @returns {JSX.Element} The rendered UserRecordingCard component.
 */
const UserRecordingCard = ({type, data, onSelect}) => {
    const {t} = useTranslation(["AccentAttributes", "Common"]);
    const emotionDescription = `${data.emotion !== "none" ? `${t(`Common.Emotion.${capitalize(data.emotion)}`)},` : ""}`
    const genderDescription = `${data.gender !== "other" ? `${t(`Common.Gender.${capitalize(data.gender)}`)},` : ""}`

    function regionalAccentArea() {
        return `
            ${data.area !== "" ? `${capitalize(data?.area ?? "")},` : ""}
            ${data.state !== "" ? capitalize(data?.state ?? "") + "," : ""} 
            ${capitalize(data?.country ?? "")}
        `
    }

    function accentPerson() {
        return `${emotionDescription} ${genderDescription} ${data?.age ? t(`Common.AgeProfile.${capitalize(data.age)}`) : ""}`
    }

    return (
        <Card className="recording-card">
            <CardHeader 
                title={type === "Regional" ? regionalAccentArea() : capitalize(data?.race ?? "")}
                subheader={accentPerson()}
            />
            
            <CardContent>
                {data.keywords.length > 0 
                    ? 
                    <KeywordsChips keywords={data.keywords}/>
                    :
                    // <NoKeywords/>
                    null
                }
            </CardContent>

            <CardActions>
                <Flex width={"100%"} justifyContent={"space-between"}>
                    <Button onClick={() => {onSelect(data)}}>{t("Common:Listen")}</Button>
                    <Text 
                        padding={5}
                        backgroundColor={data ? getGeneralStatusColor(data.status) : "gray"} 
                        borderRadius={5}
                        color="white"
                    >
                        {data ? t(`Common:${getGeneralStatus(data.status)}`) : "N/A"}
                    </Text>
                </Flex>
            </CardActions>
        </Card>
    )
}

export default UserRecordingCard;