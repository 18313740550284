import AWS from "aws-sdk";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import UserRecordingCard from "./UserRecordingCard";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CircularProgress, Tab, Tabs } from "@mui/material";
import { Flex, ScrollView, useAuthenticator } from "@aws-amplify/ui-react";
import UserRecordingsFilterSelect from "./UserRecordingsFilterSelect";
import { listFantasyAccents, listRegionalAccents } from "../../helpers/recordings";
import { fantasyCategories, generalCategories, regionalCategories } from "../../constants/recordingConstants";

/**
 * UserRecordingsList component displays a list of user recordings.
 * It allows the user to filter and select recordings based on accent type and categories.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onRecordSelected - Callback function triggered when a recording is selected.
 * @param {Function} props.onAccentTypeChange - Callback function triggered when the accent type is changed.
 * @param {Function} props.onAudioChange - Callback function triggered when the audio source URL is changed.
 * @param {React.Ref} ref - Reference to the component.
 * @returns {JSX.Element} UserRecordingsList component.
 */
const UserRecordingsList = forwardRef(({onRecordSelected = () => {}, onAccentTypeChange = () => {}, onAudioChange = () => {}}, ref) => {
    const s3 = new AWS.S3();
    const location = useLocation();
    const {user} = useAuthenticator((context) => [context.user]);
    const {t} = useTranslation(["Common"])

    const [fetchingRecords, setFetchingRecords] = useState(true)
    const [audioSourceURL, setAudioSourceURL] = useState("");
    const [currentAccentTypeSelected, setCurrentAccentTypeSelected] = useState("")
    const [recordingsData, setRecordingsData] = useState([]);
    const [currentTab, setCurrentTab] = useState("all");
    const [categoryValues, setCategoryValues] = useState({});
    const [categoryFilters, setCategoryFilters] = useState({});

    const dataFilter = user && location.pathname === "/recordings" ? {owner: {contains: user.attributes.sub}} : {}; // mainly for admins to see only their own recordings at recordings/

    const onListAllAccentsHandler = async (status = null) => {        
        const regionalAccents = await listRegionalAccents(status, dataFilter)
        const fantasyAccents = await listFantasyAccents(status, dataFilter)
        
        setCurrentAccentTypeSelected("All");

        const data = [...regionalAccents, ...fantasyAccents]
        setRecordingsData(data)

        return data
    }

    const onListRegionalHandler = async (status = null) => {
        const data = await listRegionalAccents(status, dataFilter)
        setRecordingsData(data)

        setCurrentAccentTypeSelected("Regional");
        return data
    }

    const onListFantasyHandler = async (status = null) => {
        const data = await listFantasyAccents(status, dataFilter)
        setRecordingsData(data)

        setCurrentAccentTypeSelected("Fantasy");
        return data
    }

    const fetchRecordingHandler = async (key) => {
        const params = {
            Bucket: 'accentrecrods',
            Key: `recordings/${key}.opus`,
        }
        s3.getObject(params, (err, res) => {
            if (err) {
                console.error(err);
                return;
            }
            console.log('recording fetched successfully');
            const blob = new Blob([res.Body], { type: res.ContentType });
            const url = URL.createObjectURL(blob);
            setAudioSourceURL(url);    
        })
    }

    const editRecord = (id, newData) => {
        const recordIndex = recordingsData.findIndex(record => record.id === id)
        if (recordIndex !== -1) {
           const recordData = recordingsData[recordIndex]
           recordingsData[recordIndex] = {...recordData, ...newData}
           setRecordingsData(recordingsData)
        }
    }

    const onDelete = (id) => {
        setRecordingsData(prev => prev.filter(record => record.id !== id))
    }

    useImperativeHandle(ref, () => ({editRecord, onDelete})) // gives parent access to recordings

    const onTabChange = async (event, value) => {
        setFetchingRecords(true)

        if (value === "regional") {
            await onListRegionalHandler();
        }
        else if (value === "fantasy") {
            await onListFantasyHandler();
        }
        else {
            await onListAllAccentsHandler();
        }

        setCurrentTab(value)
        setCategoryFilters({})

        setFetchingRecords(false)
    }

    const addCategoryFilter = (key, value) => {
        const newFilters = {...categoryFilters};
        newFilters[key] = value;
        
        setCategoryFilters(newFilters)
    }

    const categoryFilter = (record) => {
        for (let key in categoryValues) {
            if (key in categoryFilters && categoryFilters[key] && categoryFilters[key] !== record[key]) {
                return false;
            }
        }

        return true
    }

    useEffect(() => {
        onTabChange(null, currentTab);    
    }, [currentTab])

    useEffect(() => {
        onAccentTypeChange(currentAccentTypeSelected);
    }, [currentAccentTypeSelected])

    useEffect(() => {
        onAudioChange(audioSourceURL);
    }, [audioSourceURL])
    
    useEffect(() => {
        const addToNewCategoryValuesSet = (record, categories) => {
            categories.forEach(category => {
                if (!(category in newValues)) {
                    newValues[category] = new Set();
                }
                
                if (category in record) {
                    const item = record[category]
                
                    if (item && item !== "") 
                        newValues[category].add(record[category]);
                }
            });
        }
        
        setCategoryValues({})

        const newValues = {}
        recordingsData.forEach(record => {
            if (currentAccentTypeSelected === "Regional") {
                addToNewCategoryValuesSet(record, regionalCategories)
            }
            else if (currentAccentTypeSelected === "Fantasy") {
                addToNewCategoryValuesSet(record, fantasyCategories)
            }
            else {
                addToNewCategoryValuesSet(record, regionalCategories)
                addToNewCategoryValuesSet(record, fantasyCategories)
            }

            addToNewCategoryValuesSet(record, generalCategories)
        })

        setCategoryValues(newValues)
    }, [recordingsData])

    return (
        <Flex direction={"column"} alignItems={"center"} marginTop={10}>
            <Tabs variant="scrollable" value={currentTab} onChange={(e, v) => {setCurrentTab(v)}}>
                <Tab label={t("All")} value="all"/>
                <Tab label={t("Regional")} value="regional"/>
                <Tab label={t("Fantasy")} value="fantasy"/>
            </Tabs>

            <Flex direction={"column"} width={"80%"} marginTop={25}>
                <Flex justifyContent={"center"}>
                    <ScrollView display={"flex"} justifyContent="space-between">
                        {Object.entries(categoryValues).map(([key, value]) => (
                            <UserRecordingsFilterSelect onChange={(value) => addCategoryFilter(key, value)} category={key} categoryValues={value}/>
                        ))}
                    </ScrollView>
                </Flex>

                <Flex justifyContent={"center"}>
                    <Flex margin={5} justifyContent={{ base: "center", medium: "space-evenly"}} wrap={"wrap"} >
                        {!fetchingRecords 
                            ?
                            (recordingsData?.filter(categoryFilter).length > 0 ?
                                recordingsData.filter(categoryFilter).map((data, i) => (
                                    <UserRecordingCard
                                        key={i}
                                        type={data?.race ? "Fantasy" : "Regional"} 
                                        data={data}
                                        onSelect={async (e) => {
                                            await fetchRecordingHandler(e.PrimaryKey);
                                            onRecordSelected({...e})
                                        }}
                                    />
                                ))
                                :
                                <h2>No Recordings</h2>
                            )
                            :
                            <CircularProgress/>
                        }
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
})

export default UserRecordingsList;