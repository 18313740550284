/* src/App.js */
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useContextData, DataProvider } from './context/DataContext';
import '@aws-amplify/ui-react/styles.css';
import HomePage from './pages/HomePage';
import AuthPage from './pages/AuthPage';
import LanguageSelectPage from './pages/LanguageSelectPage';
import AppWrapper from './components/general/AppWrapper';
import AccentFormPage from './pages/AccentFormPage';
import RecordingPage from './pages/RecordingPage';
import UserRecordingsPage from './pages/UserRecordingsPage';
import AdminRoutes from './components/admin/AdminRoutes';
import AccountPage from './pages/AccountPage';
import ReferralPage from './pages/ReferralPage';
import InvitationPage from './pages/InvitationPage';
import LegalAgreementPage from './pages/LegalAgreementPage';

const App = () => {
  return (
    <Router>
      <DataProvider>
        <AppWrapper>
          <Routes>
            <Route path='/' exact Component={HomePage}/>
            <Route path='/auth' Component={AuthPage}/>
            <Route path='/language' Component={LanguageSelectPage}/>
            <Route path='/accentform' Component={AccentFormPage}/>
            <Route path='/recorder' Component={RecordingPage}/>
            <Route path='/recordings' Component={UserRecordingsPage}/>
            <Route path='/account' Component={AccountPage}/>
            <Route path='/referral/' element={<ReferralPage/>}/>
            <Route path='/referral/:referral' element={<ReferralPage/>}/>
            <Route path="/invitation/:id" element={<InvitationPage/>}/>
            <Route path="/legal-agreement" Component={LegalAgreementPage}/>
            <Route path='/admin/*' Component={AdminRoutes}/>'
          </Routes>
        </AppWrapper>
      </DataProvider>
    </Router>
  )
}

export default App;