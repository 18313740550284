import { Flex } from '@aws-amplify/ui-react';
import { GridToolbarContainer } from '@mui/x-data-grid';

/**
 * CustomGridToolBar component.
 * This component is intended for the RecordingsTable component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components to render.
 * @returns {JSX.Element} The rendered CustomGridToolBar component.
 */
const CustomGridToolBar = ({children}) => {
    return (
        <GridToolbarContainer>
            <Flex maxWidth={"100%"} direction={"column"}>
                {children}
            </Flex>
        </GridToolbarContainer>
    )
}

export default CustomGridToolBar;