/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTodo = /* GraphQL */ `
  mutation CreateTodo(
    $input: CreateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    createTodo(input: $input, condition: $condition) {
      id
      name
      language
      description
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTodo = /* GraphQL */ `
  mutation UpdateTodo(
    $input: UpdateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    updateTodo(input: $input, condition: $condition) {
      id
      name
      language
      description
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTodo = /* GraphQL */ `
  mutation DeleteTodo(
    $input: DeleteTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    deleteTodo(input: $input, condition: $condition) {
      id
      name
      language
      description
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRegionalAccent = /* GraphQL */ `
  mutation CreateRegionalAccent(
    $input: CreateRegionalAccentInput!
    $condition: ModelRegionalAccentConditionInput
  ) {
    createRegionalAccent(input: $input, condition: $condition) {
      id
      PrimaryKey
      status
      ownerId
      email
      keywords
      gender
      age
      emotion
      country
      state
      area
      recordingLength
      ReferredByUserId
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRegionalAccent = /* GraphQL */ `
  mutation UpdateRegionalAccent(
    $input: UpdateRegionalAccentInput!
    $condition: ModelRegionalAccentConditionInput
  ) {
    updateRegionalAccent(input: $input, condition: $condition) {
      id
      PrimaryKey
      status
      ownerId
      email
      keywords
      gender
      age
      emotion
      country
      state
      area
      recordingLength
      ReferredByUserId
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRegionalAccent = /* GraphQL */ `
  mutation DeleteRegionalAccent(
    $input: DeleteRegionalAccentInput!
    $condition: ModelRegionalAccentConditionInput
  ) {
    deleteRegionalAccent(input: $input, condition: $condition) {
      id
      PrimaryKey
      status
      ownerId
      email
      keywords
      gender
      age
      emotion
      country
      state
      area
      recordingLength
      ReferredByUserId
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFantasyAccent = /* GraphQL */ `
  mutation CreateFantasyAccent(
    $input: CreateFantasyAccentInput!
    $condition: ModelFantasyAccentConditionInput
  ) {
    createFantasyAccent(input: $input, condition: $condition) {
      id
      PrimaryKey
      status
      race
      emotion
      ownerId
      email
      keywords
      gender
      age
      recordingLength
      ReferredByUserId
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFantasyAccent = /* GraphQL */ `
  mutation UpdateFantasyAccent(
    $input: UpdateFantasyAccentInput!
    $condition: ModelFantasyAccentConditionInput
  ) {
    updateFantasyAccent(input: $input, condition: $condition) {
      id
      PrimaryKey
      status
      race
      emotion
      ownerId
      email
      keywords
      gender
      age
      recordingLength
      ReferredByUserId
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFantasyAccent = /* GraphQL */ `
  mutation DeleteFantasyAccent(
    $input: DeleteFantasyAccentInput!
    $condition: ModelFantasyAccentConditionInput
  ) {
    deleteFantasyAccent(input: $input, condition: $condition) {
      id
      PrimaryKey
      status
      race
      emotion
      ownerId
      email
      keywords
      gender
      age
      recordingLength
      ReferredByUserId
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAccentRecording = /* GraphQL */ `
  mutation CreateAccentRecording(
    $input: CreateAccentRecordingInput!
    $condition: ModelAccentRecordingConditionInput
  ) {
    createAccentRecording(input: $input, condition: $condition) {
      id
      AccentId
      Type
      transcriptFileName
      recordingProgression
      audioURL
      length
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAccentRecording = /* GraphQL */ `
  mutation UpdateAccentRecording(
    $input: UpdateAccentRecordingInput!
    $condition: ModelAccentRecordingConditionInput
  ) {
    updateAccentRecording(input: $input, condition: $condition) {
      id
      AccentId
      Type
      transcriptFileName
      recordingProgression
      audioURL
      length
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAccentRecording = /* GraphQL */ `
  mutation DeleteAccentRecording(
    $input: DeleteAccentRecordingInput!
    $condition: ModelAccentRecordingConditionInput
  ) {
    deleteAccentRecording(input: $input, condition: $condition) {
      id
      AccentId
      Type
      transcriptFileName
      recordingProgression
      audioURL
      length
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAccentTranscript = /* GraphQL */ `
  mutation CreateAccentTranscript(
    $input: CreateAccentTranscriptInput!
    $condition: ModelAccentTranscriptConditionInput
  ) {
    createAccentTranscript(input: $input, condition: $condition) {
      id
      emotion
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAccentTranscript = /* GraphQL */ `
  mutation UpdateAccentTranscript(
    $input: UpdateAccentTranscriptInput!
    $condition: ModelAccentTranscriptConditionInput
  ) {
    updateAccentTranscript(input: $input, condition: $condition) {
      id
      emotion
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAccentTranscript = /* GraphQL */ `
  mutation DeleteAccentTranscript(
    $input: DeleteAccentTranscriptInput!
    $condition: ModelAccentTranscriptConditionInput
  ) {
    deleteAccentTranscript(input: $input, condition: $condition) {
      id
      emotion
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAccentReferral = /* GraphQL */ `
  mutation CreateAccentReferral(
    $input: CreateAccentReferralInput!
    $condition: ModelAccentReferralConditionInput
  ) {
    createAccentReferral(input: $input, condition: $condition) {
      refereeId
      referralId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAccentReferral = /* GraphQL */ `
  mutation UpdateAccentReferral(
    $input: UpdateAccentReferralInput!
    $condition: ModelAccentReferralConditionInput
  ) {
    updateAccentReferral(input: $input, condition: $condition) {
      refereeId
      referralId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAccentReferral = /* GraphQL */ `
  mutation DeleteAccentReferral(
    $input: DeleteAccentReferralInput!
    $condition: ModelAccentReferralConditionInput
  ) {
    deleteAccentReferral(input: $input, condition: $condition) {
      refereeId
      referralId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAccentRecordingInvitation = /* GraphQL */ `
  mutation CreateAccentRecordingInvitation(
    $input: CreateAccentRecordingInvitationInput!
    $condition: ModelAccentRecordingInvitationConditionInput
  ) {
    createAccentRecordingInvitation(input: $input, condition: $condition) {
      id
      userId
      senderEmail
      recipientEmail
      recipientDesc
      accentParams
      expires
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAccentRecordingInvitation = /* GraphQL */ `
  mutation UpdateAccentRecordingInvitation(
    $input: UpdateAccentRecordingInvitationInput!
    $condition: ModelAccentRecordingInvitationConditionInput
  ) {
    updateAccentRecordingInvitation(input: $input, condition: $condition) {
      id
      userId
      senderEmail
      recipientEmail
      recipientDesc
      accentParams
      expires
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAccentRecordingInvitation = /* GraphQL */ `
  mutation DeleteAccentRecordingInvitation(
    $input: DeleteAccentRecordingInvitationInput!
    $condition: ModelAccentRecordingInvitationConditionInput
  ) {
    deleteAccentRecordingInvitation(input: $input, condition: $condition) {
      id
      userId
      senderEmail
      recipientEmail
      recipientDesc
      accentParams
      expires
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const sendRecordingInvitation = /* GraphQL */ `
  mutation SendRecordingInvitation(
    $recipientDetails: AWSJSON!
    $accentData: AWSJSON!
  ) {
    sendRecordingInvitation(
      recipientDetails: $recipientDetails
      accentData: $accentData
    )
  }
`;
export const sendReferralEmail = /* GraphQL */ `
  mutation SendReferralEmail($recipientEmail: String!, $referralLink: String!) {
    sendReferralEmail(
      recipientEmail: $recipientEmail
      referralLink: $referralLink
    )
  }
`;
