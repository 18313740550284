import { Route, Routes } from 'react-router-dom';
import AdminPortalPage from '../../pages/AdminPortalPage';
import AdminRecordingApprovalPage from '../../pages/AdminRecordingApprovalPage';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useContextData } from '../../context/DataContext';
import AdminTranscriptGenerationPage from '../../pages/AdminTranscriptGenerationPage';

/**
 * Renders the admin routes based on user authentication and admin status.
 * If the user is not authenticated or is not an admin, it redirects to the specified route.
 *
 * @returns {JSX.Element} The rendered admin routes.
 */
const AdminRoutes = () => {
    const {isAdmin} = useContextData()
    const {user} = useAuthenticator((context) => [context.user]);
    const navigation = useNavigate();
    let from = useLocation().state?.from?.pathname || '/';

    useEffect(() => {
        if (user && !isAdmin) {
            navigation(from, { replace: true });
        }
    }, [user])

    return (
        <Routes>
            <Route path='/' Component={AdminPortalPage}/>
            <Route path='/approvals' Component={AdminRecordingApprovalPage}/>
            <Route path='/create_transcript' Component={AdminTranscriptGenerationPage}/>
        </Routes>
    )
}

export default AdminRoutes;