import { useState, useEffect } from 'react';
import { Button, Heading, View, useAuthenticator, Flex, Label } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import withAppNavbar from '../components/general/withAppNavbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Share from '../components/general/Share';
import TutorialService from '../services/TutorialService';
import ReactPlayer from 'react-player';
import TutorialPopup from '../components/general/TutorialPopup';
import TutorialPlayer from '../components/general/TutorialPlayer';
import Dialog from '../components/general/Dialog';

const initialState = {
    ownerId: '',
    AccentType: '',
    keywords: [],
    race: '',
    gender: '',
    age: '',
    country: '',
    state: '',
    area: '',
};
  
/**
 * Represents the Home component.
 * 
 * @returns {JSX.Element} The rendered Home component.
 */
const Home = () => {
    const tutorialService = new TutorialService();
    const [unauthTutorialUrl, setUnauthTutorialUrl] = useState("");
    const {t} = useTranslation(["HomePage", "Common"]);
    const navigation = useNavigate();
    const {authStatus, user} = useAuthenticator((context) => [context.authStatus, context.user]);
    const [isRegionalAccentInfoDialogOpen, setIsRegionalAccentInfoDialogOpen] = useState(false);
    const [isFantasyAccentInfoDialogOpen, setIsFantasyAccentInfoDialogOpen] = useState(false);
    const [formState, setFormState] = useState(initialState)

    const displayRegionalAccentInfo = (e) => {
        e.stopPropagation();
        setIsRegionalAccentInfoDialogOpen(true)
    }

    const displayFantasyAccentInfo = (e) => {
        e.stopPropagation();
        setIsFantasyAccentInfoDialogOpen(true)
    }

    useEffect(() => {
        if (authStatus === "authenticated") {
            const userId = user.attributes.sub;
            formState.ownerId = userId;
        }
        else {
            tutorialService.getTutorial("unauthHome").then((url) => {
                setUnauthTutorialUrl(url)
            })
        }
    }, [authStatus])

    return (
        <View style={styles.container}>
            <div className="container">
                {authStatus !== 'authenticated' 
                    ?
                    <Flex height={"75vh"} alignItems={"center"}>
                        <TutorialPlayer
                            url={unauthTutorialUrl}
                        />
                    </Flex>
                    :
                    <Flex direction={"column"}>
                        <Flex marginTop={"10vh"} direction={"column"} alignItems={"center"} justifyContent={"space-evenly"}>
                            <Heading className='pick-accent-text' textAlign={"center"} level={1}>{t("Common:WhichAccentWouldYouLikeToRecord")}</Heading>
                            <Flex marginTop={100} justifyContent={"center"} wrap={"wrap"} maxWidth={"100vw"}>
                                {/* <Button style={styles.button} onClick={() => {navigation("/accentform", {state: {accentType: "regional", isForAnotherUser: true}})}}>
                                    <Flex direction={"column"}>
                                        <FontAwesomeIcon icon="people-arrows" size="2xl"/>
                                        {t("Common:FamilyFriends")}
                                    </Flex>
                                </Button> */}
                                <Button style={styles.button} onClick={() => {navigation("/accentform", {state: {accentType: "regional"}})}}>
                                    <Label style={{position: "absolute", top: 0, left: "10px"}} onClick={displayRegionalAccentInfo} >?</Label>
                                    <Flex direction={"column"}>
                                        <FontAwesomeIcon icon="globe" size="2xl"/>
                                        {t("Common:Regional")}
                                    </Flex>
                                </Button>
                                <Button style={styles.button} onClick={() => {navigation("/accentform", {state: {accentType: "fantasy"}})}}>
                                    <Label style={{position: "absolute", top: 0, left: "10px"}} onClick={displayFantasyAccentInfo} >?</Label>
                                    <Flex direction={"column"}>
                                        <FontAwesomeIcon icon="dragon" size="2xl"/>
                                        {t("Common:Fantasy")}
                                    </Flex>
                                </Button>
                            </Flex>
                        </Flex>
                        
                        <Flex direction={"column"} marginTop={"10vh"} justifyContent={"center"} alignItems={"center"}>
                            <TutorialPopup videoName="authHome" />
                            <Share />
                        </Flex>

                        <Dialog
                            open={isRegionalAccentInfoDialogOpen}
                            onClose={setIsRegionalAccentInfoDialogOpen}
                            title={t("Common:Regional")}
                            text={t("RegionalAccentInfoText")}
                            yesFn={() => {}}
                        />
                        <Dialog
                            open={isFantasyAccentInfoDialogOpen}
                            onClose={setIsFantasyAccentInfoDialogOpen}
                            title={t("Common:Fantasy")}
                            text={t("FantasyAccentInfoText")}
                            yesFn={() => {}}
                        />
                    </Flex>
                }

            </div>
        </View>  
    )
}

const styles = {
    container: { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center", padding: 20 },
    todo: {  marginBottom: 15 },
    input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
    todoName: { fontSize: 20, fontWeight: 'bold' },
    todoDescription: { marginBottom: 0 },
    button: { position: "relative", minWidth: 200, maxWidth: 200, fontSize: 25, padding: '50px' }
}

export default withAppNavbar(Home);