import { Paper, capitalize } from "@mui/material";
import KeywordsChipsItem from "./KeywordsChipsItem";

/**
 * Renders a list of keyword chips.
 *
 * @param {Array} keywords - The array of keywords.
 * @returns {JSX.Element} - The rendered keyword chips.
 */
const KeywordsChips = ({keywords}) => {
    const chips = keywords.map((keyword, index) => ({key: index, label: capitalize(keyword)}));

    return (
        <>
            {keywords && keywords.length > 0 && keywords[0] !== "" && 
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        p: 0.5,
                        m: 0.5,
                    }}
                    component="ul"
                >
                    {chips.map((data) => <KeywordsChipsItem data={data} />)}
                </Paper>
            }
        </>
    )
}

export default KeywordsChips;