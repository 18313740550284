import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ReactPlayer from "react-player";
import screenfull from "screenfull";
import TutorialService from "../../services/TutorialService";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Flex } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import { getTextWidth } from "../../helpers/text";
import TutorialPlayer from "./TutorialPlayer";

/**
 * TutorialPopup component displays a tutorial video in an accordion format.
 * It allows the user to expand and collapse the accordion to view the video.
 * The component fetches the tutorial video URL from the TutorialService based on the provided video name.
 * The title of the tutorial is displayed in the accordion summary.
 * The size of the video player is responsive and adjusts based on the window size.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.style - The custom styles to be applied to the component.
 * @param {string} props.title - The title of the tutorial.
 * @param {string} props.videoName - The name of the tutorial video.
 * @returns {JSX.Element} The TutorialPopup component.
 */
const TutorialPopup = ({style = {}, title = "", videoName = ""}) => {
    const {t} = useTranslation(["Common"])
    const defaultSizeFactor = 3;
    const [domLoaded, setDomLoaded] = useState()
    const tutorialService = new TutorialService();
    const [tutorialTitle, setTutorialTitle] = useState(title)
    const [expanded, setExpanded] = useState(false)
    const [url, setUrl] = useState("");
    const [accordionExpansionStyle, setAccordionExpansionStyle] = useState({})
    const [sizeFactor, setSizeFactor] = useState(defaultSizeFactor);

    const handleChange = () => {
        setExpanded(!expanded);
    };

    const onResize = () => {
        let newSize = defaultSizeFactor
        if (window.innerWidth < 1000) {
            newSize = defaultSizeFactor + 1
        }
        if (window.innerWidth < 700) {
            newSize = defaultSizeFactor + 2
        }
        if (window.innerWidth < 500) {
            newSize = defaultSizeFactor + 3
        }

        setSizeFactor(newSize)
    }

    useEffect(() => {
        setDomLoaded(true)
    }, [])

    useEffect(() => {
        // Set the width of the accordion summary based on the content width
        
        if (domLoaded) {
            const el = document.getElementById("tutorial-popup-summary-content")
            const elFont = window.getComputedStyle(el).fontFamily
            setAccordionExpansionStyle(expanded ? {} : {width: 150 + getTextWidth(el.textContent, elFont)})
        }

        // if (expanded) {
        //     screenfull.request(document.querySelector('.tutorial-video'))
        // }

    }, [title, domLoaded, expanded])

    useEffect(() => {
        if (tutorialTitle === "") {
            setTutorialTitle(t("WhatDoIDoHere"))
        }
    }, [tutorialTitle])

    useEffect(() => {
        tutorialService.getTutorial(videoName).then((res) => {
            setUrl(res)
        })
    }, [videoName])

    return (
        <Accordion style={{background: "transparent", boxShadow: "none", ...accordionExpansionStyle, ...style}} expanded={expanded} onChange={handleChange}>
            <AccordionSummary expandIcon={<FontAwesomeIcon color="#c7c7c7" icon={"caret-down"} />}>
                <Flex width={"100%"} justifyContent={"center"}>
                    <Flex id="tutorial-popup-summary-content" alignItems={"center"} justifyContent={"center"}>
                        <FontAwesomeIcon size="lg" color="gray" icon="fa-question-circle"/>
                        {tutorialTitle}
                    </Flex>
                </Flex>
            </AccordionSummary>
            <AccordionDetails>
                <Flex justifyContent={"center"} alignItems={"center"}>
                    <TutorialPlayer
                        url={url}
                        playing={expanded}
                        muted={!expanded}
                    />
                </Flex>
            </AccordionDetails>
        </Accordion>
    )
}

export default TutorialPopup;