import { Alert, Snackbar } from "@mui/material"
import { useEffect, useState } from "react";

/**
 * A Snackbar component that displays a message with a specified severity.
 *
 * @component
 * @param {boolean} open - Determines whether the Snackbar is open or closed. Default is false.
 * @param {string} message - The message to be displayed in the Snackbar. Default is "An error has occurred! Please wait and try again."
 * @param {string} severity - The severity of the message. Can be one of "error", "warning", "info", or "success". Default is "error".
 * @returns {JSX.Element} The rendered Snackbar component.
 */
const AccentSnackBar = ({open = false, message = "An error has occurred! Please wait and try again.", severity = "error"}) => {
    const [isOpen, setIsOpen] = useState(open);

    const handleClose = (event = null, reason = null) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsOpen(false);
    };

    useEffect(() => {
        setIsOpen(open);
    }, [open])

    return (
        <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    )
}

export default AccentSnackBar;