import { useEffect, useState } from "react";
import AccentTable from "./AccentTable";
import { useAuthenticator } from "@aws-amplify/ui-react"
import CustomGridToolBar from "./CustomGridToolBar";
import { formTableData } from "../../helpers/formTableData";
import AWS from 'aws-sdk';
import { useGridApiRef } from '@mui/x-data-grid';
import { Tab, Tabs } from "@mui/material";
import { useContextData } from "../../context/DataContext";
import { AcceptedReview, RaceRejected, RaceUnderReview, RejectedReview, UnderReview, statusLabels } from "../../constants/recordingConstants";
import { getFantasyAccentsByStatus } from "../../services/FantasyAccentService";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { listFantasyAccents, listRegionalAccents } from "../../helpers/recordings";

/**
 * A table component for displaying and managing recordings.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onRowSelected - A callback function triggered when a row is selected. Default value is an empty function.
 * @param {Function} props.onRowIsSelected - A callback function triggered when a row is deselected. Default value is an empty function.
 * @param {Function} props.onAccentTypeChange - A callback function triggered when the accent type is changed. Default value is an empty function.
 * @param {Function} props.onAudioChange - A callback function triggered when the audio source URL is changed. Default value is an empty function.
 * @param {Function} props.gridAPIRefInstance - A callback function to receive the grid API reference instance. Default value is an empty function.
 * @returns {JSX.Element} The RecordingsTable component.
 */
const RecordingsTable = ({
    onRowSelected = (e) => {},
    onRowIsSelected = (e) => {}, 
    onAccentTypeChange = (e) => {},
    onAudioChange = (e) => {},
    gridAPIRefInstance = (e) => {}
}) => {
    const s3 = new AWS.S3();
    const location = useLocation();
    const dataFilter = location.pathname === "/recordings" ? {owner: {contains: user.attributes.sub}} : {}; // mainly for admins to see only their own recordings at recordings/
    const gridAPIref = useGridApiRef();

    const {t} = useTranslation(["Common"])
    const {isAdmin} = useContextData();
    const {user} = useAuthenticator((context) => [context.user]);
    
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [isRowSelected, setIsRowSelected] = useState(false);
    const [rowSelected, setRowSelected] = useState(null);
    const [audioSourceURL, setAudioSourceURL] = useState('');
    const [currentAccentTypeSelected, setCurrentAccentTypeSelected] = useState("")
    const [columnModel, setColumnModel] = useState({email: isAdmin, id: false, PrimaryKey: false, keywords: false})
    const [currentTab, setCurrentTab] = useState("regional");
    const [currentSubTab, setCurrentSubTab] = useState(UnderReview);

    const setTableData = async (data) => {
        const tableData = formTableData(data, gridAPIref);
        setColumns(tableData.columns);
        setRows(tableData.rows);
    }

    const onListRegionalHandler = async (status = null) => {
        setCurrentAccentTypeSelected("Regional");

        let data = await listRegionalAccents(status, dataFilter)
        
        setTableData(data);
    }

    const onListFantasyHandler = async (status = null) => {
        setCurrentAccentTypeSelected("Fantasy");

        let data = await listFantasyAccents(status, dataFilter)

        setTableData(data);
    }

    const fetchRecordingHandler = async (key) => {
        const params = {
            Bucket: 'accentrecrods',
            Key: `recordings/${key}.opus`,
        }
        s3.getObject(params, (err, res) => {
            if (err) {
                console.error(err);
                return;
            }
            console.log('recording fetched successfully');
            const blob = new Blob([res.Body], { type: res.ContentType });
            const url = URL.createObjectURL(blob);
            setAudioSourceURL(url);    
        })
    }

    const onTabChange = (event, value) => {
        if (!isAdmin) {
            if (value === "regional") {
                onListRegionalHandler();
            }
            else {
                onListFantasyHandler();
            }
        }

        setCurrentTab(value)
    }

    const onSubTabChange = (event, value) => {
        if (currentTab === "regional") {
            onListRegionalHandler(value);
        }
        else {
            onListFantasyHandler(value);
        }

        setCurrentSubTab(value);
    }

    useEffect(() => {
        // send over ref to the parent to be able to edit the table
        gridAPIRefInstance(gridAPIref);
    }, [])

    useEffect(() => {
        // if changing between regional/fantasy tab
        if (isAdmin) {
            onSubTabChange(null, currentSubTab);
        }
        else {
            onTabChange(null, currentTab);
        }
    }, [currentTab])

    useEffect(() => {
        onRowSelected(rowSelected);
    }, [rowSelected])

    useEffect(() => {
        onRowIsSelected(isRowSelected);
    }, [isRowSelected])
    
    useEffect(() => {
        onAccentTypeChange(currentAccentTypeSelected);
    }, [currentAccentTypeSelected])

    useEffect(() => {
        onAudioChange(audioSourceURL);
    }, [audioSourceURL])

    return (
        <AccentTable
            apiRef={gridAPIref}
            columns={columns}
            rows={rows}
            columnVisibilityModel={columnModel}
            header={() => (
                <CustomGridToolBar>
                    <Tabs variant="scrollable" value={currentTab} onChange={(e, v) => {setCurrentTab(v)}}>
                        <Tab label={t("Regional")} value="regional"/>
                        <Tab label={t("Fantasy")} value="fantasy"/>
                    </Tabs>
                    {isAdmin && 
                        <Tabs variant="scrollable" value={currentSubTab} onChange={onSubTabChange}>
                            {currentTab == "fantasy" && <Tab label={statusLabels.RaceUnderReview} value={RaceUnderReview}/>}
                            {currentTab == "fantasy" && <Tab label={statusLabels.RaceRejected} value={RaceRejected} />}
                            <Tab label={statusLabels.UnderReview} value={UnderReview}/>
                            <Tab label={statusLabels.RejectedReview} value={RejectedReview}/>
                            <Tab label={statusLabels.AcceptedReview} value={AcceptedReview}/>
                        </Tabs>
                    }
                </CustomGridToolBar>
            )}
            isRowSelected={(e) => {
                if (e.length === 0) {
                    setAudioSourceURL("");
                }
                setIsRowSelected(e)
            }}
            onRowSelected={async (e) => {
                await fetchRecordingHandler(e.PrimaryKey);
                setRowSelected({...e});
            }}
        />
    )
}

export default RecordingsTable;