import BackButton from "./BackButton"
import "../../styles/workflow.scss"

/**
 * Renders the controls for a workflow page e.g. accent forms
 *
 * @param {Object} props - The component props.
 * @param {Object} props.style - The inline style for the controls container.
 * @param {ReactNode} props.children - The child components to render.
 * @param {ReactNode} props.ForwardButton - The forward button component to render.
 * @returns {ReactNode} The rendered workflow page controls.
 */
const WorkflowPageControls = ({style = {}, children, ForwardButton = null}) => {
    return (
        <>
            <div className="workflowPageControlsContainer" style={style}>
                <BackButton/>
                {ForwardButton && ForwardButton}
            </div>
            {children}
        </>
    )
}

export default WorkflowPageControls;