import LanguageSelection from '../components/general/LanguageSelect';
import withAppNavbar from '../components/general/withAppNavbar';

/**
 * Renders the LanguageSelectPage component.
 * @returns {JSX.Element} The rendered LanguageSelectPage component.
 */
const LanguageSelectPage = () => {
    return (
        <div style={styles.container}>
            <LanguageSelection isSiteWide={true}/>
        </div>
    )
}

const styles = {
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
    }
  }

export default withAppNavbar(LanguageSelectPage);