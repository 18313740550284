import { Flex, useAuthenticator } from "@aws-amplify/ui-react";
import { AppBar, Container, Typography, Box, IconButton, Toolbar, Menu, MenuItem, Avatar, Tooltip, Button, Skeleton   } from "@mui/material";
import { API, graphqlOperation } from "aws-amplify";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { listAccentRecordings } from "../../graphql/customQueries";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContextData } from "../../context/DataContext";

// higher-order component
const withAppNavbar = (Component) => {
    /**
     * Represents the navigation bar component for the application.
     * This component displays the navigation menu.
     *
     * @component
     * @example
     * return (
     *   <WithAppNavbar />
     * )
     */
    const WithAppNavbar = (props) => {
        const {isAdmin} = useContextData();
        const {t} = useTranslation(["HomePage", "Common"]);
        const navigation = useNavigate();
        const {user, authStatus, signOut} = useAuthenticator((context) => [context.user, context.authStatus, context.signOut])
        const [userHasRecordings, setUserHasRecordings] = useState(false);
        const [anchorElNav, setAnchorElNav] = useState(null);
        const [anchorElUser, setAnchorElUser] = useState(null);
        const [isLoading, setIsLoading] = useState(true);
        
        const noAuthRequiredPages = [
            {
                title: t("Common:Language"),
                onClick: () => {
                    navigation("/language")
                }
            }
        ]

        const pages = authStatus === "authenticated" ? [
            isAdmin ? {
                title: "Admin",
                onClick: () => {
                    navigation("/admin")
                }
            } : null,
            {
                title: t("Common:RecordAccent"),
                onClick: () => {
                    navigation("/")
                }
            },
            userHasRecordings ? {
                title: t("Common:Recordings"),
                onClick: () => {
                    navigation("/recordings")
                }
            }
            : null,
            ...noAuthRequiredPages,
        ] : [...noAuthRequiredPages]

        const settings = authStatus === "authenticated" ? [
            {
                title: t("Common:Account"),
                onClick: () => {
                    navigation("/account")
                }
            },
            {
                title: t("SignOut"),
                onClick: () => {
                    signOut();
                    navigation("/")
                }
            }
        ] : 
        [
            {
                title: t("SignIn"),
                onClick: () => {
                    navigation("/")
                    setTimeout(() => {
                        navigation("/auth", {state: {to: "signIn"}})
                    }, 0)
                }
            },
            {
                title: t("CreateAccount"),
                backgroundColor: "black",
                onClick: () => {
                    navigation("/")
                    setTimeout(() => {
                        navigation("/auth", {state: {to: "signUp"}})
                    }, 0)                
                }
            }
        ];


        const NavSkeleton = <Skeleton variant="text" width={200}/>;
      
        const handleOpenNavMenu = (event) => {
          setAnchorElNav(event.currentTarget);
        };
        const handleOpenUserMenu = (event) => {
          setAnchorElUser(event.currentTarget);
        };
      
        const handleCloseNavMenu = (onCloseFn = () => {}) => {
          setAnchorElNav(null);
          
          if (typeof onCloseFn === "function" && onCloseFn) {
            onCloseFn()
          }
        };
      
        const handleCloseUserMenu = (onCloseFn = () => {}) => {
          setAnchorElUser(null);
          if (typeof onCloseFn === "function" && onCloseFn) {
            onCloseFn()
          }
        };

        useEffect(() => {
            if (authStatus !== "configuring") {
                if (authStatus === "authenticated") {
                    /*  Here we check if the user has recordings.
                        
                        Ideally, we should add a limit to prevent querying all the data, but it seems appsync/dynamodb for some reason apply the limit to the entire accent recordings table first
                        then any filters. It seems a filter is added automatically according to the auth rules we set in the graphql schema.
                    */
                    API.graphql(graphqlOperation(listAccentRecordings, {filter: {owner: {contains: user.attributes.sub}}})).then((res) => {
                        setUserHasRecordings(res.data.listAccentRecordings.items.length > 0)
                    }).catch((err) => {
                        console.error(err)
                    }).finally(() => {
                        setIsLoading(false)
                    })
                }
                else {
                    setIsLoading(false)
                }
            }
        }, [authStatus])

        return (
            <>
                <AppBar sx={{background: "rgb(260 260 260)", color: "black", borderBottom: "2px solid rgb(220 220 220)", padding: 0.5}} position="static" elevation={0}>
                    <Container maxWidth="xl">
                        <Toolbar disableGutters>
                            <Typography
                                    variant="h6"
                                    noWrap
                                    component="a"
                                    href="/"
                                    sx={{
                                        mr: 2,
                                        display: { xs: 'none', md: 'flex' },
                                        fontFamily: 'monospace',
                                        fontWeight: 700,
                                        letterSpacing: '.3rem',
                                        color: 'inherit',
                                        textDecoration: 'none',
                                    }}
                            >
                                Project Accents
                            </Typography>

                            <Box marginRight={3} sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none'} }}>
                                {pages.length != 0 && <FontAwesomeIcon icon={"bars"} size="2x" onClick={handleOpenNavMenu}/>}
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                    }}
                                >
                                    {!isLoading ? pages.map((page) => {
                                            return (
                                                page != null &&
                                                <MenuItem key={page.title} onClick={() => handleCloseNavMenu(page.onClick)}>
                                                    <Typography textAlign="center">{page.title}</Typography>
                                                </MenuItem>
                                            )
                                        })
                                        : NavSkeleton
                                    }
                                </Menu>
                            </Box>
                            <Typography
                                variant="h6"
                                noWrap
                                component="a"
                                href="/"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'flex', md: 'none' },
                                    flexGrow: 1,
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    textAlign: "center",
                                    textWrap: "pretty"
                                }}
                            >
                                Project Accents
                            </Typography>
                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                {!isLoading ? pages.map((page) => {
                                        return (
                                            page != null &&
                                                <Button
                                                    key={page.title}
                                                    onClick={() => handleCloseNavMenu(page.onClick)}
                                                    sx={{ textTransform: "capitalize", my: 2, color: 'rgb(50 50 50)', display: 'block' }}
                                                >
                                                    <Typography textAlign="center">{page.title}</Typography>
                                                </Button>
                                        )
                                    })
                                    :
                                    NavSkeleton
                                }
                            </Box>

                            <Box sx={{ flexGrow: 0 }}>
                                {authStatus === "authenticated" 
                                    ?
                                    <Tooltip title={t("Common:Account")}>
                                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                            <Avatar alt={t("Common:Account")} />
                                        </IconButton>
                                    </Tooltip>
                                    :
                                    <Box sx={{ flexGrow: 1, display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
                                        {settings.map((setting) => {
                                            return (
                                                setting != null &&
                                                <Button 
                                                    sx={{
                                                        "&:hover": {
                                                            color: "rgb(50 50 50)"
                                                        },
                                                        my: 0.3, 
                                                        mx: 1, 
                                                        color: setting?.backgroundColor ? 'white' : 'rgb(50 50 50)', 
                                                        backgroundColor: setting?.backgroundColor, 
                                                        display: 'block',
                                                        textTransform: 'capitalize'
                                                    }}
                                                    key={setting.title} 
                                                    onClick={() => handleCloseUserMenu(setting.onClick)}
                                                >
                                                    {setting.title}
                                                </Button>
                                            )
                                        })}
                                    </Box>
                                }
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={() => {handleCloseUserMenu()}}
                                >
                                    {settings.map((setting) => {
                                        return (
                                            setting != null &&
                                            <MenuItem key={setting.title} backgroundColor={setting?.backgroundColor} onClick={() => handleCloseUserMenu(setting.onClick)}>
                                                <Typography textAlign="center">{setting.title}</Typography>
                                            </MenuItem>
                                        )
                                    })}
                                </Menu>
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>

                <Component {...props}/>
            </>
        )
    };

    return WithAppNavbar;
}

export default withAppNavbar;