/**
 * LanguageSelection component for selecting the language.
 * 
 * @component
 * @param {boolean} [isSiteWide=true] - Indicates if the language selection is site-wide.
 * @param {function} [onChange=(e) => {}] - Callback function triggered when the language selection changes.
 * @returns {JSX.Element} LanguageSelection component.
 */
import { useEffect, useState } from'react';
import { SelectField, Button } from '@aws-amplify/ui-react';
import { useContextData } from '../../context/DataContext';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

const LanguageSelection = ({isSiteWide = true, onChange = (e) => {}}) => {
  const defaultLang = "en";
  const {t} = useTranslation(["AccentAttributes", "Common", "HomePage"])
  const navigation = useNavigate();
  const [language, setLanguage] = useState("")
  const {selectedSiteLanguage, setSelectedSiteLanguage} = useContextData();

  const setSiteLanguage = () => {
    if (isSiteWide) {
      console.log("Set language to: ", selectedSiteLanguage);
      Cookies.set("language", selectedSiteLanguage);
      navigation("/");
    }
  }

  useEffect(() => {
    if (isSiteWide && !selectedSiteLanguage) {
      setSelectedSiteLanguage(defaultLang);
    }
    else {
      setLanguage(defaultLang);
    }

    return () => {
      setSelectedSiteLanguage(Cookies.get("language") || defaultLang)
    }
  }, [])

  useEffect(() => {
    onChange(language);
  }, [language])

  return (
    <>
      <SelectField
        label={t("Common:Language")}
        descriptiveText={!isSiteWide && t("Common:PleaseSelectTheThing", {thing: t("Common:Language")})}
        value={isSiteWide ? selectedSiteLanguage : language}
        onChange={(e) => {isSiteWide ? setSelectedSiteLanguage(e.target.value) : setLanguage(e.target.value)}}
      >
            <option value="en">English</option>
            <option value="es">Español</option>
      </SelectField>

      {isSiteWide && <Button margin={25} onClick={setSiteLanguage}>{t("Common:Confirm")}</Button>}
    </>
  );
}

const styles = {
  container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      flexDirection: "column",
  }
}

 export default LanguageSelection;