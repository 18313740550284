import { Button, Flex, Heading, Text } from "@aws-amplify/ui-react";
import AudioPlayback from "../../components/audio/AudioPlayback";
import RecordingsTable from "../../components/admin/RecordingsTable";
import { useEffect, useRef, useState } from "react";
import { onReviewHandler as _onReviewHandler, getNewStatusOnAction } from "../../helpers/recordings";
import AWS from 'aws-sdk';
import { useTranslation } from "react-i18next";
import FullscreenDialog from "../../components/general/FullscreenDialog";
import Transcript from "../../components/audio/Transcript";
import AccentDescription from "../../components/general/AccentDescription";
import { onStatusCellActionHandler } from "../../helpers/formTableData";
import { useContextData } from "../../context/DataContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withAppNavbar from "../../components/general/withAppNavbar";
import UserRecordingsList from "../../components/audio/UserRecordingsList";
import { delAccentRecording } from "../../services/RecordingService";

/**
 * Renders a component for displaying and managing recordings.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.type - The type of display for the recordings (either "cards" or "grid").
 * @returns {JSX.Element} The rendered component.
 */
const RecordingsPartial = ({type}) => {
    const {isAdmin} = useContextData();
    const {t} = useTranslation(["Common"])
    const s3 = new AWS.S3();
    const [isRecordingReviewDialogOpen, setIsRecordingReviewDialogOpen] = useState(false);
    const [isRecordSelected, setIsRecordSelected] = useState(false);
    const [recordSelected, setRecordSelected] = useState(null);
    const [audioSourceURL, setAudioSourceURL] = useState('');
    const [isPlaybackEnabled, setIsPlaybackEnabled] = useState(false)
    const [currentAccentTypeSelected, setCurrentAccentTypeSelected] = useState("")
    const [gridAPIRef, setGridAPIRef] = useState()
    const cardsRef = useRef();
    const audioRef = useRef(null);

    const onRecordingDelete = async (id, key) => {
        // delete from backend
        await delAccentRecording(currentAccentTypeSelected, key);

        if (type === "grid") {
            gridAPIRef.current.updateRows([{ id: id, _action: 'delete' }]);
        }
        else {
            cardsRef.current.onDelete(id)
        }

        setAudioSourceURL("");
        setRecordSelected(null);
        setIsRecordSelected(false);
        setIsRecordingReviewDialogOpen(false);
    }

    const onReviewHandler = (approved) => {
        const recordData = {
            ...recordSelected,
            accentType: recordSelected?.race ? "Fantasy" : "Regional"
        }

        const nextStatus = getNewStatusOnAction(recordSelected.status, approved)

        if (type === "grid") {
            onStatusCellActionHandler(nextStatus, recordData, gridAPIRef);    
        }
        else {
            _onReviewHandler(recordSelected.PrimaryKey, nextStatus, recordData.accentType)
            cardsRef.current.editRecord(recordSelected.id, {status: nextStatus})
        }
    }

    const ReviewButton = ({approved}) => {
        const nextStatus = getNewStatusOnAction(recordSelected?.status, approved);
        return (
            <Button onClick={() => onReviewHandler(approved)}>
                <FontAwesomeIcon style={{marginRight: 5}} color={approved ? "green" : "red"} title={nextStatus} icon={approved ? "check" : "xmark"}/>
                <span>{nextStatus}</span>
            </Button>
        )
    }

    const playback = () => {
        console.log(isPlaybackEnabled)
        if (audioSourceURL === "" || isPlaybackEnabled) {
            return;
        }

        setIsPlaybackEnabled(true)

        let audio = new Audio(audioSourceURL)
        audio.play()

        audioRef.current = audio
    }

    const stopPlayback = () => {
        if (audioRef.current !== null) {
            audioRef.current.pause()
            audioRef.current = null
        }

        setIsPlaybackEnabled(false)
    }

    useEffect(() => {
        return stopPlayback;
    }, [])

    return (
        <div>
            {type === "cards"
                ?
                <UserRecordingsList
                    ref={cardsRef}
                    onRecordSelected={(e) => {
                        if (e) {
                            setCurrentAccentTypeSelected(e?.race ? "Fantasy" : "Regional")
                            setRecordSelected(e);
                            setIsRecordingReviewDialogOpen(true);
                        }
                    }}
                    onAudioChange={(e) => {setAudioSourceURL(e)}}
                    onAccentTypeChange={(e) => {setCurrentAccentTypeSelected(e)}}
                />
                :
                <RecordingsTable
                    gridAPIRefInstance={(e) => {setGridAPIRef(e)}}
                    onAudioChange={(e) => {setAudioSourceURL(e)}}
                    onRowSelected={(e) => {
                        if (e) {
                            setRecordSelected(e);
                            setIsRecordingReviewDialogOpen(true);
                        }
                    }}
                    onRowIsSelected={(e) => {setIsRecordSelected(e)}}
                    onAccentTypeChange={(e) => {setCurrentAccentTypeSelected(e)}}
                />
            }

            <FullscreenDialog 
                title="Review" 
                isOpen={isRecordingReviewDialogOpen} 
                onClose={() => {
                    stopPlayback();
                    setIsRecordingReviewDialogOpen(false);
                }}
            >
                {recordSelected &&
                    <Flex className="recording-page" direction={"column"}>
                        <AccentDescription type={currentAccentTypeSelected.toLowerCase()} data={recordSelected} />
                        <Transcript 
                            data={recordSelected}
                            playback={isPlaybackEnabled}
                            setIsPlaybackEnabled={setIsPlaybackEnabled} 
                        />
                        <FontAwesomeIcon
                            color={audioSourceURL === "" || isPlaybackEnabled ? "lightgray" : "black"}
                            onClick={playback}
                            cursor={"pointer"}
                            icon="circle-play"
                            fontSize={"3.5em"}
                        />
                    </Flex>
                }
                <Flex justifyContent={"center"} wrap={"wrap"}>
                    {isAdmin && <ReviewButton approved={false}/>}

                    <Button onClick={() => {onRecordingDelete(recordSelected.id, recordSelected.PrimaryKey)}}>                    
                        <FontAwesomeIcon style={{marginRight: 5}} icon={"trash-can"}/>
                        {t("Delete")}
                    </Button>
                    
                    {isAdmin && <ReviewButton approved={true}/>}
                </Flex>
            </FullscreenDialog>
        </div>
    );
}

export default withAppNavbar(RecordingsPartial);