import { SelectField } from '@aws-amplify/ui-react';
import { useContextData } from '../../context/DataContext';
import { useTranslation } from 'react-i18next';

/**
 * Renders a select field for selecting an age profile.
 *
 * @returns {JSX.Element} The AgeProfileSelection component.
 */
const AgeProfileSelection = () => {
  const {t} = useTranslation(["AccentAttributes", "Common", "HomePage"])
  const { selectedAgeProfile, updateAgeProfile, formErrorState } = useContextData();

  const handleAgeProfileChange = (event) => {
   // Access the selected value from the event object
   const selectedAgeProfile = event.target.value;
   
   // Update the state with the selected value
   updateAgeProfile(selectedAgeProfile);
 };

  return (
    <SelectField
      isRequired
      label={`${t("HomePage:AgeProfile")}*`}
      hasError={!formErrorState?.valid && !formErrorState?.age}
      descriptiveText={t("Common:PleaseSelectTheThing", {thing: t("HomePage:AgeProfile")})}
      onChange={handleAgeProfileChange}
      value={selectedAgeProfile}
    >
          <option value="">- {t("Common:SelectOne")} -</option>
          <option value="child">{t("Common.AgeProfile.Child")}</option>
          <option value="teenager">{t("Common.AgeProfile.Teenager")}</option>
          <option value="adult">{t("Common.AgeProfile.Adult")}</option>
          <option value="elderly">{t("Common.AgeProfile.Elderly")}</option>
    </SelectField>
  );
}

export default AgeProfileSelection;