import { API, graphqlOperation } from "aws-amplify";
import { getFantasyAccentsByStatus } from "../services/FantasyAccentService";
import { getRegionalAccentsByStatus } from "../services/RegionalAccentService";
import { UnderReview, RaceRejected, RaceUnderReview, AcceptedReview, RejectedReview } from "../constants/recordingConstants";
import { updateRegionalAccent, updateFantasyAccent } from "../graphql/mutations";

export function getGeneralStatus(status) {
    if (status === UnderReview || status === RaceUnderReview) {
        return 'Pending';
    }
    else if (status === AcceptedReview) {
        return 'Approved';
    }
    else if (status === RejectedReview || status === RaceRejected) {
        return 'Rejected';
    }
}

export function getGeneralStatusColor(status) {
    const generalStatus = getGeneralStatus(status);
    if (generalStatus === 'Pending') {
        return "#F7CB73";
    }
    else if (generalStatus === 'Approved') {
        return 'green';
    }
    else if (generalStatus === 'Rejected') {
        return 'red';
    }
}

export const getNewStatusOnAction = (currentStatus, approved = false) => {
    const reviewStatus = [UnderReview, RejectedReview, AcceptedReview].includes(currentStatus);
    const raceReviewStatus = [RaceUnderReview, RaceRejected].includes(currentStatus);
    if (approved) {
      if (reviewStatus) {
        return AcceptedReview;
      }
    
      if (raceReviewStatus) {
        return UnderReview;
      }
    } 
    else {
      if (reviewStatus) {
        return RejectedReview;
      }
    
      if (raceReviewStatus) {
        return RaceRejected;
      }
    }
  }
  
export const onReviewHandler = (key, status, accentType) => {
    if (accentType === "Regional") {
        API.graphql(graphqlOperation(updateRegionalAccent, {input: { PrimaryKey: key, status: status }}))
        .catch((err) => {console.error(err)})
    }
    else {
        API.graphql(graphqlOperation(updateFantasyAccent, {input: { PrimaryKey: key, status: status }}))
        .catch((err) => {console.error(err)})
    }
    
    console.log("Review complete. Updated recording status.")
    
    return true;
}

export const listRegionalAccents = async (status = null, dataFilter) => {
    const res = await getRegionalAccentsByStatus(status, dataFilter)
    const key = Object.keys(res.data)[0]
    
    const data = res.data[key].items
    
    return data
}

export const listFantasyAccents = async (status = null, dataFilter) => {
    const res = await getFantasyAccentsByStatus(status, dataFilter)
    const key = Object.keys(res.data)[0]
    
    const data = res.data[key].items
    
    return data
}
