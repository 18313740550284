import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import withAppNavbar from "../components/general/withAppNavbar";
import { Flex, Heading, Text, useAuthenticator } from "@aws-amplify/ui-react";
import AWS from "aws-sdk";
import { Auth } from "@aws-amplify/auth";
import { Button, CircularProgress } from "@mui/material";
import { useContextData } from "../context/DataContext";

AWS.config.update({
    region: 'eu-central-1', // e.g. 'us-west-1'
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const s3 = new AWS.S3();

/**
 * Represents a page for displaying and accepting the legal agreement.
 *
 * @returns {JSX.Element} The LegalAgreementPage component.
 */
const LegalAgreementPage = () => {
    const navigation = useNavigate();
    const location = useLocation();
    const {user, authStatus} = useAuthenticator((context) => [context.user, context.authStatus])
    const {legalAgreementAccepted, setLegalAgreementAccepted} = useContextData();
    const nextLocation = location?.state?.nextLocation ?? "/";
    const [legalText, setLegalText] = useState(false)

    const onAgree = async () => {
        await Auth.updateUserAttributes(user, {"custom:agreedToLegal": "true"})
        setLegalAgreementAccepted(true)
        navigation(nextLocation)
    }

    const onDisagree = async () => {
        await Auth.updateUserAttributes(user, {"custom:agreedToLegal": "false"})
        setLegalAgreementAccepted(false)
        navigation("/")
    }

    useEffect(() => {
        if (authStatus !== "authenticated") {
            navigation("/")
        }

        if (legalAgreementAccepted) {
            navigation(nextLocation)
        }

        const params = {
            Bucket: "accentlegal",
            Key: "projectaccents_legal_agreement.txt",
        };

        s3.getObject(params, (err, data) => {
            if (err) {
                setLegalText("Error loading legal agreement! Please refresh the page!")
                return;
            }

            setLegalText(data.Body.toString("utf-8"))
        })
    }, [])

    return (
        <Flex alignItems={"center"} justifyContent={"center"} marginTop={15} marginBottom={15}>
            <Flex width={"50%"} marginTop={25} direction={"column"} alignItems={"center"}>
                <Heading alignSelf={"center"} marginBottom={25} level={1}>Legal Agreement</Heading>
                {legalText 
                    ?
                    <Text whiteSpace={"pre-wrap"} alignSelf={"start"}>{legalText}</Text>
                    :
                    <CircularProgress/>
                }

                <Flex marginTop={5} alignSelf={"start"}>
                    <Button onClick={onAgree} variant="contained">Agree</Button>
                    <Button onClick={onDisagree} variant="contained">Disagree</Button>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default withAppNavbar(LegalAgreementPage);