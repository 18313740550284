import { SelectField } from '@aws-amplify/ui-react';
import { useContextData } from '../../context/DataContext';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useEffect } from 'react';

export let emotionOptions = {
  none: i18next.t("Common:None"),
  sad: i18next.t("AccentAttributes:Common.Emotion.Sad"),
  happy: i18next.t("AccentAttributes:Common.Emotion.Happy"),
  fear: i18next.t("AccentAttributes:Common.Emotion.Fear"),
  anger: i18next.t("AccentAttributes:Common.Emotion.Anger"),
  disgust: i18next.t("AccentAttributes:Common.Emotion.Disgust"),
  surprise: i18next.t("AccentAttributes:Common.Emotion.Surprise")
};

/**
 * Represents a component for selecting an emotion which is used in accent forms, recorder page, etc.
 * @module EmotionSelection
 * @param {Object} props - The component props.
 * @param {Object} props.fieldProps - Additional props for the SelectField component.
 * @param {string[]} props.excluding - An array of emotion options to exclude from the selection.
 * @returns {JSX.Element} The EmotionSelection component.
 */
const EmotionSelection = ({fieldProps = {}, excluding = ["none"]}) => {
  const {t} = useTranslation(["AccentAttributes", "Common", "HomePage"])
  const { selectedEmotion, updateEmotion } = useContextData();

  const filteredOptionKeys = Object.keys(emotionOptions).filter(option => !excluding.includes(option))

  const handleEmotionChange = (event) => {
   // Access the selected value from the event object
   const selectedEmotion = event.target.value;
   
   // Update the state with the selected value
   updateEmotion(selectedEmotion);
  };

  useEffect(() => {
    updateEmotion(filteredOptionKeys[0])
    emotionOptions = {
      none: t("Common:None"),
      sad: t("AccentAttributes:Common.Emotion.Sad"),
      happy: t("AccentAttributes:Common.Emotion.Happy"),
      fear: t("AccentAttributes:Common.Emotion.Fear"),
      anger: t("AccentAttributes:Common.Emotion.Anger"),
      disgust: t("AccentAttributes:Common.Emotion.Disgust"),
      surprise: t("AccentAttributes:Common.Emotion.Surprise")
    }
  }, [])

  return (
    <div>
      <SelectField
        label={t("HomePage:Emotion")}
        descriptiveText={t("Common:PleaseSelectTheThing", {thing: t("HomePage:Emotion")})}
        onChange={handleEmotionChange}
        value={selectedEmotion}
        {...fieldProps}
      >
        {filteredOptionKeys.map((option) => (
          <option key={option} value={option}>{emotionOptions[option]}</option>
        ))}
      </SelectField>
    </div>
  );
}

export default EmotionSelection;