import { useContextData } from '../../context/DataContext';
import { CountryDropdown, RegionDropdown, CountryRegionData } from "react-country-region-selector";
import i18nCountries from "i18n-iso-countries";
import { Flex, Label } from '@aws-amplify/ui-react';
import { useTranslation } from 'react-i18next';

/**
 * Renders a component for selecting a country and region.
 *
 * @returns {JSX.Element} The rendered component.
 */
const CountryRegionSelection = () => {
  const {t} = useTranslation(["AccentAttributes", "Common"])
  const { selectedSiteLanguage, selectedCountry, updateCountry, selectedRegion, updateRegion, formErrorState } = useContextData();

  const handleCountryChange = (event) => {
    // Access the selected value from the event object
    const selectedCountry = event;
    
    // Update the state with the selected value
    updateCountry(selectedCountry);
    updateRegion('');
  };

  const handleRegionChange = (event) => {
    // Access the selected value from the event object
    const selectedRegion = event;
    
    // Update the state with the selected value
    updateRegion(selectedRegion);
  };

  const getWhitelisted = () => {
    let whitelisted = []

    if (selectedSiteLanguage === "es") {
      whitelisted = [
          "MX", "ES", "AR", "CO", "VE", "PE", "CL", "EC", "GT", "CU", "BO", "DO", "HN", "PY", "SV", "NI", "CR", "PA", "UY"
      ]
    }
    
    CountryRegionData.forEach((data, i) => {
      if (whitelisted.includes(data[1])) {
        CountryRegionData[i][0] = i18nCountries.getName(data[1], selectedSiteLanguage, {select: "official"})
      }
    })

    CountryRegionData.sort((a, b) => {
      // Check if either a or b is in the whitelisted array
      const aIsWhitelisted = whitelisted.includes(a[1]);
      const bIsWhitelisted = whitelisted.includes(b[1]);
  
      // If both are whitelisted or unwhitelisted, sort alphabetically
      if (aIsWhitelisted === bIsWhitelisted) {
          return a[0].localeCompare(b[0]);
      }
  
      // If a is whitelisted and b is not, move a to a lower index
      if (aIsWhitelisted) {
          return -1;
      }
  
      // If b is whitelisted and a is not, move b to a lower index
      return 1;
    });

    return whitelisted
  }


  return (
    <Flex direction={"column"} gap="large">
      <Flex direction="column" gap="xxs">
        <Label htmlFor="country" className="block text-gray-700">{t("AccentAttributes:Regional.Country")}*</Label>
        <CountryDropdown
          defaultOptionLabel={t("Common:SelectThing", {thing: t("AccentAttributes:Regional.Country")})}
          isRequired
          hasError={!formErrorState?.valid && !formErrorState?.country}
          value={selectedCountry}
          onChange={(val) => handleCountryChange(val)}
          whitelist={getWhitelisted()}
        />
      </Flex>

      <Flex direction="column" gap="xxs">
        <Label disabled={!selectedCountry} htmlFor="region" className="block text-gray-700">{t("AccentAttributes:Regional.Region")}</Label>
        <RegionDropdown
          defaultOptionLabel={t("Common:SelectThing", {thing: t("AccentAttributes:Regional.Region")})}
          isRequired={false}
          disabled={!selectedCountry}
          country={selectedCountry}
          value={selectedRegion}
          onChange={(val) => handleRegionChange(val)}
        />
      </Flex>
    </Flex>
  );
}

export default CountryRegionSelection;