import { Flex } from "@aws-amplify/ui-react";
import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAccentRecordingInvitation } from "../graphql/queries";
import { useContextData } from "../context/DataContext";
import RecordingPage from "./RecordingPage";

/**
 * Renders the InvitationPage component.
 * 
 * @returns {JSX.Element} The rendered InvitationPage component.
 */
const InvitationPage = () => {
    const { id } = useParams();
    const [invitationData, setInvitationData] = useState(null)
    const [verified, setVerified] = useState(undefined)
    const { setFormState } = useContextData();

    const processInvitation = async () => {
        const res = await API.graphql({
            query: getAccentRecordingInvitation,
            variables: {id: id},
            authMode: "API_KEY"
        })

        const data = res.data.getAccentRecordingInvitation

        if (data === null) {
            setVerified(false)
            return
        }

        // check if expired (it can take time for db to delete the invitation)
        const currentTime = Math.floor(Date.now() / 1000);
        if (currentTime > data.expires) {
            setVerified(false)
            return
        }

        const accentFormState = JSON.parse(data.accentParams)

        setFormState(accentFormState)
        setInvitationData(data)
        setVerified(true)
    }

    useEffect(() => {
        processInvitation()
    }, [])

    return (
        <>
            {verified === undefined ?
                <p>Verifying {id}...</p>
                :
                verified ?
                <RecordingPage accentType={"regional"} invitation={invitationData}/>
                :
                <p>Bad id</p>
            }
        </>
    )
}

export default InvitationPage;