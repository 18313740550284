import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Chip, styled } from "@mui/material"

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));


/**
 * Represents a single keyword chip item.
 *
 * @component
 * @param {Object} data - The data for the keyword chip item.
 * @param {string} data.key - The unique key for the keyword chip item.
 * @param {string} data.label - The label for the keyword chip item.
 * @returns {JSX.Element} The rendered keyword chip item.
 */
const KeywordsChipsItem = ({data}) => {
    return (
        <ListItem key={data.key}>
            <Chip
                icon={<FontAwesomeIcon icon="tag"/>}
                label={data.label}
            />
        </ListItem>
    )
}

export default KeywordsChipsItem;